import { getItem, setItem } from '@/modules/shared/services/storage.service';
import { computed, onBeforeMount, ref } from 'vue';

const key = 'active_doing';
const context = ref(null);

export const useFocus = () => {
  const setFocus = async (
    title,
    imageUrl,
    doingUuid,
    playlistUuid,
    stepUuid,
    tag,
    callbackRoute = null
  ) => {
    await setItem(key, {
      title,
      imageUrl,
      doingUuid,
      playlistUuid,
      stepUuid,
      callbackRoute,
      tag,
    });
  };

  const unsetFocus = async () => {
    await setItem(key, null);
    context.value = null;
  };

  onBeforeMount(async () => {
    context.value = await getItem(key);
  });

  const hasFocus = computed(() => context.value !== null);

  return {
    context,
    hasFocus,
    setFocus,
    unsetFocus,
  };
};
