<script>
  import { computed, defineComponent } from 'vue';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import LinkCardList from '@/components/library/link-card-list/LinkCardList.vue';

  export default defineComponent({
    name: 'FocusPlaylistCard',
    components: {
      LinkCardList,
      JumHeading,
    },
    props: {
      playlist: {
        type: Object,
        required: true,
      },
      showItems: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const currentStep = computed(() =>
        props.playlist.phases.reduce(
          (acc, phase) => acc + phase.links.filter(link => link.completed).length,
          0
        )
      );
      const totalSteps = computed(() => props.playlist.challengeCount);
      const progress = computed(() => {
        if (!currentStep.value || !totalSteps.value) {
          return 1;
        }

        const product = (currentStep.value / totalSteps.value) * 100;

        return product > 1 ? product : 1;
      });

      const items = computed(() =>
        props.playlist.phases.filter(phase => phase.links.length).flatMap(phase => phase.links)
      );
      const showNumberOfItems = 2;
      const highlightedItems = computed(() => {
        const firstIncompleteIndex = items.value.findIndex(item => !item.completed);

        if (firstIncompleteIndex === -1) {
          return items.value.slice(0, showNumberOfItems);
        }

        return items.value.slice(firstIncompleteIndex, firstIncompleteIndex + showNumberOfItems);
      });

      return {
        progress,
        currentStep,
        totalSteps,
        highlightedItems,
      };
    },
  });
</script>

<template>
  <div class="focus-playlist-container">
    <router-link
      v-slot="{ navigate }"
      :to="{ name: 'playlist-detail', params: { slug: playlist.slug } }"
      custom
    >
      <div
        class="playlist-card"
        :style="{ 'background-image': `url(${playlist.img})` }"
        @click="navigate"
      >
        <div class="h-group">
          <jum-heading h5 bold class="title">
            {{ playlist.title }}
          </jum-heading>

          <div class="progress">
            <div class="progress-bar">
              <div class="progress-bar__fill" :style="{ width: `${progress}%` }"></div>
            </div>
            <div class="progress-label">{{ currentStep }}/{{ totalSteps }}</div>
          </div>
        </div>
      </div>
    </router-link>

    <div v-if="showItems" class="items">
      <link-card-list :links="highlightedItems" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .progress {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  .progress-label {
    font-size: 14px;
    font-weight: 700;
  }

  .progress-bar {
    background-color: #9e9e9e;
    border-radius: 8px;
    height: 6px;
    width: 100%;

    &__fill {
      background-color: #f94d3f;
      border-radius: 8px;
      height: 100%;
    }
  }

  .playlist-card {
    align-items: end;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    display: inline-flex;
    flex-shrink: 0;
    height: 220px;
    overflow: hidden;
    padding: 16px;
    position: relative;
    scroll-snap-align: start;
    transition: background 0.25s ease-in-out;
    width: 100%;

    &::after {
      background: linear-gradient(180deg, rgba(23, 23, 23, 0) 21.73%, #171717 77.74%);
      content: '';
      inset: 0;
      position: absolute;
    }

    .title {
      font-size: 1rem;
      line-height: 1rem * 1.3;
    }

    .subtitle {
      color: #9e9e9e;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 0.75rem * 1.3;
    }

    .body {
      font-size: 0.8rem;
      font-weight: 300;
      line-height: 0.8rem * 1.3;
    }

    .h-group {
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;
      width: 100%;
      z-index: 1;

      > * {
        margin: 0;
      }
    }
  }

  .focus-playlist-container {
    &:hover {
      cursor: pointer;
    }
  }

  .items {
    background-color: #171717;
    border-radius: 8px;
    margin-top: -16px;
    padding: 16px;
    transition: background 0.25s ease-in-out;
  }
</style>
