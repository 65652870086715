import { render, staticRenderFns } from "./FloatingCard.vue?vue&type=template&id=42ebe98c&scoped=true"
import script from "./FloatingCard.vue?vue&type=script&lang=js"
export * from "./FloatingCard.vue?vue&type=script&lang=js"
import style0 from "./FloatingCard.vue?vue&type=style&index=0&id=42ebe98c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42ebe98c",
  null
  
)

/* custom blocks */
import block0 from "./FloatingCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports