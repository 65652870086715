<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcLinkGroup',
  });
</script>

<template>
  <ul class="fc-link-group">
    <slot></slot>
  </ul>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-link-group {
    list-style: none;
    margin: 0;
    padding: 0;
  }
</style>
