<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcStickyBottom',
  });
</script>

<template>
  <div class="fc-sticky-bottom">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-sticky-bottom {
    bottom: 0;
    position: fixed;
    width: 100%;
  }
</style>
