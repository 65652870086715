import RegisterPage from '@/modules/shared/auth/pages/register/RegisterPage.vue';
import PremiumPage from '@/modules/core/pages/PremiumPage.vue';

export const registerRoutes = (router, guards) => {
  const routes = [
    {
      path: '/',
      name: 'entry',
      component: RegisterPage,
      beforeEnter: guards,
      meta: {
        noPayWall: true,
      },
    },
    {
      path: '/premium',
      name: 'premium',
      component: PremiumPage,
      beforeEnter: guards,
      meta: {
        noPayWall: true,
      },
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
