<script>
  import { defineComponent } from 'vue';
  import { JumButton, JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import { EXTERNAL_LINKS } from '@/modules/shared/constants/jumbo.const';
  import FcCheckList from '@/modules/shared/components/check-list/CheckList.vue';
  import { useInAppBrowser } from '@/modules/core/composables/useInAppBrowser';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { useVueProxy } from '@/composables/useVueProxy';

  export default defineComponent({
    name: 'TryPremium',
    components: {
      DefaultSpacer,
      FcCheckList,
      JumHeading,
      JumButton,
      JumIcon,
    },
    setup() {
      const { i18n } = useVueProxy();
      const { open } = useInAppBrowser();

      return {
        open,
        checkList: Object.values(i18n.messages[i18n.locale].home.info.premiumBenefits),
      };
    },
    computed: {
      EXTERNAL_LINKS() {
        return EXTERNAL_LINKS;
      },
    },
  });
</script>

<template>
  <div class="card">
    <div class="card__image">
      <img :src="require('@/assets/images/foodcoach-premium.webp')" alt="Premium" />
    </div>

    <div class="card__body">
      <jum-heading h3>
        <strong> {{ $t('home.headings.foodCoachPremium') }}</strong>
      </jum-heading>

      <fc-check-list :list="checkList" :icon-size="20" />

      <default-spacer s />

      <a
        class="cta"
        href="https://www.theathletesfoodcoach.com"
        @click.prevent="open('https://www.theathletesfoodcoach.com/')"
      >
        <jum-icon icon="jum-external-link" />
        {{ $t('home.buttons.discoverPremium') }}
      </a>

      <default-spacer s />

      <router-link v-slot="{ navigate }" :to="{ name: 'premium' }" custom>
        <jum-button block @click="navigate">
          {{ $t('home.buttons.getWinterTrial') }}
        </jum-button>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .cta {
    display: flex;
    font-weight: 700;
    gap: 4px;
    text-decoration: none;
  }

  .card {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    overflow: hidden;

    &__image {
      img {
        width: 100%;
      }
    }

    &__body {
      padding: 8px 16px 16px;
    }

    .jum-heading {
      margin-bottom: 12px;
    }
  }
</style>
