<script>
  import { JumHeading, JumInputRadio } from '@blancofoodcoach/kompas';
  import FcInputRadioPanel from '@/modules/shared/components/input-radio-panel/InputRadioPanel';
  import FcLink from '@/modules/shared/components/link/Link';
  import FcPrivacyStatementPopover from '@/modules/shared/components/privacy-statement/PrivacyStatementPopover';
  import ORGANIZATION_QUERY from '@/modules/shared/graphql/queries/getOrganization.query.graphql';
  import { defineComponent } from 'vue';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';

  export default defineComponent({
    name: 'AcceptProTermsConditions',
    components: {
      DomPurify,
      FcLink,
      JumHeading,
      JumInputRadio,
      FcInputRadioPanel,
    },
    props: {
      value: {
        type: Boolean,
      },
    },
    apollo: {
      organization: {
        query: ORGANIZATION_QUERY,
        update: ({ getOrganization }) => getOrganization.organization,
      },
    },
    data({ value }) {
      return {
        CHOICES: [true, false],
        choice: value,
      };
    },
    watch: {
      choice(newValue) {
        this.$emit('input', newValue);
      },
    },
    methods: {
      triggerPrivacyStatementPopover() {
        this.$popovery({
          component: FcPrivacyStatementPopover,
        });
      },
    },
  });
</script>

<template>
  <div v-if="organization">
    <jum-heading class="margin-bottom-m">
      <dom-purify :html="$t('pro.coachesWantSeeDetails', { organization: organization.name })" />
    </jum-heading>

    <fc-input-radio-panel
      v-for="choiceValue in CHOICES"
      :key="choiceValue"
      class="radio"
      :checked="value === choiceValue"
      :data-test-id="`${choiceValue}ProTermsConditions`"
    >
      <jum-input-radio v-model="choice" name="accept" checked :value="choiceValue">
        {{ $t(`pro.choices.${choiceValue}`) }}
      </jum-input-radio>
    </fc-input-radio-panel>

    <span class="more-information">
      {{ $t('pro.moreInformation') }}
      <fc-link @click="triggerPrivacyStatementPopover">
        {{ $t('shared.links.privacyStatement') }}
      </fc-link>
    </span>
  </div>

  <fc-content-spinner v-else />
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .jum-heading {
    text-align: left;
  }

  .radio {
    width: 100%;

    + .radio {
      margin-top: $spacing-s !important;
    }
  }

  .more-information {
    display: block;
    font-size: $font-size-small;
    line-height: $line-height-small;
    margin-top: $spacing-m;
    text-align: center;
  }
</style>
