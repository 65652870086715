<script>
  import { defineComponent } from 'vue';
  import DoingDetailPopover from '@/components/popovers/doing-detail/DoingDetailPopover.vue';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { useI18n } from '@/modules/core/composables/useI18n';
  import { useFocus } from '@/composables/useFocus';

  export default defineComponent({
    name: 'FloatingCard',
    setup() {
      const { popovery } = useVueProxy();
      const { t } = useI18n();
      const { context, hasFocus } = useFocus();

      const openDoingPopover = () => {
        popovery({
          title: t('popoverTitle'),
          component: DoingDetailPopover,
        });
      };

      return {
        openDoingPopover,
        context,
        hasFocus,
      };
    },
  });
</script>

<template>
  <div v-if="hasFocus" class="floating-card" @click="openDoingPopover">
    <div class="image">
      <img :src="context.imageUrl" alt="Doing image" />
    </div>

    <span class="title">
      {{ context.title }}
    </span>
  </div>
</template>

<style scoped lang="scss">
  .floating-card {
    align-items: center;
    background-color: #000;
    border-radius: 8px;
    bottom: 8px;
    color: #fff;
    display: flex;
    font-size: 0.75rem;
    gap: 8px;
    left: 0;
    margin: 0 20px;
    min-height: 54px;
    position: absolute;
    right: 0;
    z-index: 1000;

    .image {
      border: 1px solid #000;
      border-radius: 8px 0 0 8px;
      height: 54px;
      min-width: 54px;
      overflow: hidden;
      width: 54px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .title {
      line-height: 1.1rem;
      padding-right: 12px;
    }
  }
</style>

<i18n>
{
  "en": {
    "popoverTitle": "To-do list"
  },
  "nl": {
    "popoverTitle": "To-do lijst"
  }
}
</i18n>
