import { onBeforeMount, ref } from 'vue';
import http from '@/modules/shared/auth/services/http/strapiHttp.service';
import { createMedalQuery, Medal } from '@/util/gamification/medals';
import { useAsyncQuery } from '@/modules/shared/utils/apollo.util';
import MEDALS_QUERY from '@/graphql/queries/medals.query.graphql';

export const useMedals = (sport, tagFilter) => {
  const isLoading = ref(true);
  const medals = ref([]);

  const query = createMedalQuery(sport, tagFilter, undefined);

  const retrieveMedals = async () => {
    const { result } = await useAsyncQuery(MEDALS_QUERY, null, 0, 'no-cache');

    const response = await http.get(`/medals?${query}`);
    return response.data.data.map(item =>
      Medal.createFromStrapiResponse(item, result.value?.medals ?? [])
    );
  };

  onBeforeMount(async () => {
    isLoading.value = true;
    medals.value = await retrieveMedals();
    isLoading.value = false;
  });

  return {
    medals,
    isLoading,
    retrieveMedals,
  };
};
