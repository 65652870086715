<script>
  import DOMPurify from 'dompurify';
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    props: {
      html: {
        type: String,
        required: true,
      },
      tag: {
        type: String,
        default: 'span',
        validate: value => ['span', 'div', 'strong', 'p', 'li'].includes(value),
      },
    },
    setup(props) {
      const sanitizedHtml = computed(() => DOMPurify.sanitize(props.html));

      return {
        sanitizedHtml,
      };
    },
  });
</script>

<template>
  <!-- eslint-disable vue/no-v-html vue/no-v-text-v-html-on-component -->
  <component :is="tag" v-html="sanitizedHtml" />
</template>
