export default {
  carbsPerHourExplanation: [
    {
      text: 'De hoeveelheid koolhydraten die je per uur nodig hebt, hangt af van de duur en intensiteit van je training. Ook speelt de mate waarin je spijsvertering is getraind om koolhydraten in de bloedbaan te verwerken en op te nemen een belangrijke rol.',
    },
    {
      title: 'Training tot 60 minuten',
      text: 'Het is niet nodig om extra koolhydraten in te nemen. Je lichaam kan genoeg energie opslaan uit de maaltijden die je voor de training hebt genomen om de workout te voltooien.',
    },
    {
      title: 'Training van 60 tot 150 minuten',
      text: 'Afhankelijk van de intensiteit en duur van de oefening zullen de energievoorraden in het lichaam worden uitgeput. Het wordt aanbevolen om 30 tot 60 gram koolhydraten per uur in te nemen, vanaf het eerste uur.',
    },
    {
      title: 'Training langer dan 150 minuten',
      text: 'We raden minimaal 60 tot 90 gram koolhydraten per uur aan, vanaf het eerste uur. Vooral tijdens intensieve training is een hoge inname aanbevolen om je energieniveau zo goed mogelijk op peil te houden. Gebruik een combinatie van verschillende soorten koolhydraten om 90 gram per uur te verteren en op te nemen.',
    },
    {
      title: 'Wanneer neem ik 90 tot 120 gram koolhydraten per uur?',
      text: 'De hoeveelheid koolhydraten die jij op kunt nemen per uur is trainbaar. Deze richtlijnen zijn gebaseerd op de gemiddelde duursporter. Wil jij een specifieker en individueel afgestemd plan? Ga aan de slag met de medailles “Fuel during training” en daarna “Training the gut” en stel je vragen aan FoodCoach AI.',
    },
    {
      title: 'Extra tip!',
      text: 'Je lichaam heeft vocht nodig om koolhydraten op te nemen. Vergeet dus niet voldoende te drinken (+/- 500 ml per uur). Zonder vocht heeft het geen zin om een grote hoeveelheid koolhydraten te consumeren.',
    },
    {
      title: 'Plan en actuele inname',
      text: 'Net als een training kan ook je koolhydraat-inname tijdens de training soms afwijken van je plan. Na het synchoriseren en koppelen van je actuele data, laten we hier dus ook zien hoe je actuele koolhydraat-inname is vergeleken met je geplande koolhydraat-inname per uur.',
    },
  ],
};
