export const TRAINING_TYPES = {
  STRENGTH_TRAINING: 'Strength training',
  CARDIO_STRENGTH_TRAINING: 'Cardio + strength training',
  CARDIO_GROUP_TRAINING: 'Cardio/group training',
  CIRCUIT_CROSSFIT_TRAINING: 'CrossFit',
};

export const trainingTypesTranslations = () => ({
  recovery: 'Active recovery',
  strength: TRAINING_TYPES.STRENGTH_TRAINING,
  endurance: 'Endurance training',
  enduranceLowCarbs: 'Endurance training (low carb)',
  tempo: 'Tempo training',
  carbLoading: 'Carb loading',
  interval: 'Interval training',
  normalRace: 'Normal race',
  normalRaceLateStart: 'Normal race (late start)',
  hardRace: 'Hard race',
  hardRaceLateStart: 'Hard race (late start)',
  timeTrial: 'Time trial',
  cycloCross: 'Female CX TVL',
  matchDayKeeper: 'Match (goalkeeper)',
  matchDayPlayer: 'Match (Field player)',
  normalTraining: 'Normal training',
  intensiveTraining: 'Intensive training',
  keeperTraining: 'Goalkeeper training',
  strengthLotsResistanceLittleRest: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLotsResistanceLotsRest: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLittleResistanceLittleRest: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLittleResistanceLotsRest: TRAINING_TYPES.STRENGTH_TRAINING,
  cardioStrengthEasy: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
  cardioStrengthModerate: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
  cardioStrengthIntensive: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
  cardioGroupLessonEasy: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
  cardioGroupLessonModerate: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
  cardioGroupLessonIntensive: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
  circuitCrossfitModerate: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
  circuitCrossfitIntensive: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
});
