export const TRAINING_TYPES = {
  STRENGTH_TRAINING: 'Krachttraining',
  CARDIO_STRENGTH_TRAINING: 'Cardio + krachttraining',
  CARDIO_GROUP_TRAINING: 'Cardio/groepsles',
  CIRCUIT_CROSSFIT_TRAINING: 'CrossFit',
};

export const trainingTypesTranslations = () => ({
  recovery: 'Actief herstel',
  strength: TRAINING_TYPES.STRENGTH_TRAINING,
  endurance: 'Duurtraining',
  enduranceLowCarbs: 'Duurtraining (low carb)',
  tempo: 'Tempotraining',
  carbLoading: 'Carb loading',
  interval: 'Intervaltraining',
  normalRace: 'Normale race',
  normalRaceLateStart: 'Normale race (late start)',
  hardRace: 'Zware race',
  hardRaceLateStart: 'Zware race (late start)',
  timeTrial: 'Tijdrit',
  cycloCross: 'Female CX TVL',
  matchDayKeeper: 'Wedstrijd (keeper)',
  matchDayPlayer: 'Wedstrijd (veldspeler)',
  normalTraining: 'Normale training',
  intensiveTraining: 'Intensieve training',
  keeperTraining: 'Keeperstraining',
  strengthLotsResistanceLittleRest: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLotsResistanceLotsRest: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLittleResistanceLittleRest: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLittleResistanceLotsRest: TRAINING_TYPES.STRENGTH_TRAINING,
  cardioStrengthEasy: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
  cardioStrengthModerate: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
  cardioStrengthIntensive: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
  cardioGroupLessonEasy: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
  cardioGroupLessonModerate: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
  cardioGroupLessonIntensive: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
  circuitCrossfitModerate: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
  circuitCrossfitIntensive: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
});
