import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import VueApollo from 'vue-apollo';
import { httpLink } from '@/modules/shared/graphql/links/http.link';
import { logErrorLink } from '@/modules/shared/graphql/links/logError.link';
import { authClientResolvers } from '@/modules/shared/graphql/resolvers/resolvers';
import { unauthorizedErrorLink } from '@/modules/shared/graphql/links/unauthorizedErrorLink';
import { serviceUnavailableLink } from '@/modules/shared/graphql/links/serviceUnavailableLink';
import introspectionQueryResultData from './fragment-types.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Links
const authClientLinks = ApolloLink.from([
  unauthorizedErrorLink(),
  serviceUnavailableLink(),
  logErrorLink(),
  httpLink(),
]);

// Cache - Shared across clients
const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: o => {
    switch (o.__typename) {
      case 'User':
        return `${o.__typename}:${o.customerId}`;
      case 'Feature':
        return `${o.__typename}:${o.name}`;
      case 'Recipe': {
        // eslint-disable-next-line no-console
        if (o.id === undefined) console.error(`${o.__typename} misses 'id' property`);
        // eslint-disable-next-line no-console
        if (o.quantity === undefined) console.error(`${o.__typename} misses 'quantity' property`);
        return `${o.__typename}:${o.id}:${o.quantity}`;
      }
      case 'Product':
        return `${o.__typename}:${o.id}:${o.requiredSkuQuantity}`;
      default:
        return defaultDataIdFromObject(o);
    }
  },
});

// Client
const authClient = new ApolloClient({
  link: authClientLinks,
  resolvers: authClientResolvers,
  cache,
  shouldBatch: true,
  query: {
    fetchPolicy: 'no-cache',
  },
});

export default new VueApollo({
  defaultClient: authClient,
  $query: {
    notifyOnNetworkStatusChange: true,
  },
});
