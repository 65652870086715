<script>
  import { defineComponent, ref } from 'vue';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumHeading, JumInputCheckbox } from '@blancofoodcoach/kompas';

  export default defineComponent({
    name: 'DoingDetail',
    components: {
      JumInputCheckbox,
      DefaultSpacer,
      JumHeading,
    },
    props: {
      doing: {
        type: Object,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      isLocked: {
        type: Boolean,
        default: false,
      },
      tag: {
        type: String,
        default: undefined,
      },
    },
    setup(_, { emit }) {
      const progress = ref([]);

      const update = value => {
        emit('update', value);
      };

      return {
        progress,
        update,
      };
    },
  });
</script>

<template>
  <div class="doing-detail" :class="{ [tag]: true }">
    <div v-if="doing.coverImageUrl" class="image">
      <img :src="doing.coverImageUrl" :alt="`Image for ${doing.title}`" />
    </div>

    <default-spacer s />

    <section>
      <jum-heading h3 bold>
        {{ doing.title }}
      </jum-heading>
    </section>

    <section v-if="doing.what.length">
      <jum-heading h4 bold>
        {{ $t('whatTitle') }}
      </jum-heading>

      <template v-if="isActive">
        <ul class="checklist" :class="{ locked: isLocked }">
          <li v-for="step in doing.what" :key="step.uuid">
            <jum-input-checkbox
              v-model="step.checked"
              :name="step.text"
              :value="step.uuid"
              @change="update(step)"
            >
              {{ step.text }}
            </jum-input-checkbox>
          </li>
        </ul>
      </template>

      <template v-else>
        <ol>
          <li v-for="step in doing.what" :key="step.uuid">
            <p class="newline">
              {{ step.text }}
            </p>
          </li>
        </ol>
      </template>
    </section>

    <section v-if="doing.why">
      <jum-heading h4 bold>
        {{ $t('whyTitle') }}
      </jum-heading>

      <p class="newline">
        {{ doing.why }}
      </p>
    </section>
  </div>
</template>

<style scoped lang="scss">
  section {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 45px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .image {
    overflow: hidden;

    img {
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .body {
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 1rem * 1.3 !important;
  }

  .h-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    z-index: 1;

    > * {
      margin: 0;
    }
  }

  .newline {
    white-space: pre-line;
  }

  ol,
  .checklist {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
  }

  .checklist {
    padding: 0;

    &.locked {
      pointer-events: none;
    }
  }

  ol {
    padding-left: 30px;

    li {
      position: relative;

      &::before {
        align-items: center;
        background-color: #000;
        border-radius: 50%;
        color: #fff;
        content: counter(list-item);
        display: inline-flex;
        font-size: 0.9rem;
        font-weight: 600;
        height: 20px;
        justify-content: center;
        left: -30px;
        margin-right: 10px;
        position: absolute;
        top: 2px;
        width: 20px;
      }
    }
  }

  .perform {
    ol {
      li {
        &::before {
          background-color: #f94d3f;
        }
      }
    }
  }

  .recover {
    ol {
      li {
        &::before {
          background-color: #ffb736;
        }
      }
    }

    .checklist {
      :deep(.box.checked) {
        background-color: #ffb736;
        border-color: #ffb736;
      }
    }
  }

  .protect {
    ol {
      li {
        &::before {
          background-color: #0d8933;
        }
      }
    }

    .checklist {
      :deep(.box.checked) {
        background-color: #0d8933;
        border-color: #0d8933;
      }
    }
  }

  .coach {
    ol {
      li {
        &::before {
          background-color: #908ac0;
        }
      }
    }

    .checklist {
      :deep(.box.checked) {
        background-color: #908ac0;
        border-color: #908ac0;
      }
    }
  }

  .specials {
    ol {
      li {
        &::before {
          background-color: #11aeed;
        }
      }
    }

    .checklist {
      :deep(.box.checked) {
        background-color: #11aeed;
        border-color: #11aeed;
      }
    }
  }
</style>

<i18n>
{
  "en": {
    "whatTitle": "What to do",
    "whyTitle": "Why"
  },
  "nl": {
    "whatTitle": "What to do",
    "whyTitle": "Why"
  }
}
</i18n>
