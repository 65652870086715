<script>
  import { defineComponent } from 'vue';
  import { JumIcon } from '@blancofoodcoach/kompas';
  import { useInAppBrowser } from '@/modules/core/composables/useInAppBrowser';

  export default defineComponent({
    name: 'LinkList',
    components: {
      JumIcon,
    },
    props: {
      links: {
        type: Array,
        required: true,
      },
    },
    setup() {
      const { open } = useInAppBrowser();

      return {
        open,
      };
    },
  });
</script>

<template>
  <ul class="link-list">
    <li v-for="link in links" :key="link.id">
      <template v-if="link.external">
        <template v-if="link.url.includes('mailto')">
          <a :href="link.url" target="_blank">
            {{ link.title }} <jum-icon icon="jum-external-link" />
          </a>
        </template>
        <template v-else>
          <a :href="link.url" @click.prevent="open(link.url)">
            {{ link.title }} <jum-icon icon="jum-external-link" />
          </a>
        </template>
      </template>
      <template v-else-if="link.customIcon">
        <router-link :to="link.url">
          {{ link.title }} <jum-icon :icon="link.customIcon" />
        </router-link>
      </template>
      <template v-else>
        <router-link :to="link.url">
          {{ link.title }} <jum-icon icon="jum-chevron-right" />
        </router-link>
      </template>
    </li>
  </ul>
</template>

<style scoped lang="scss">
  .link-list {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      overflow: hidden;

      &:not(:last-child) {
        border-bottom: 1px solid #e3e3e3;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      a {
        align-items: center;
        background-color: #fff;
        display: flex;
        font-weight: 300;
        height: 54px;
        justify-content: space-between;
        padding: 16px;
        text-decoration: none;
        width: 100%;
      }
    }
  }
</style>
