<script>
  import ProTermsConditionsPopover from '@/modules/home/components/popovers/ProTermsConditionsPopover';
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { MODALS } from '@/modules/shared/constants/modals.const';
  import MODAL_SHOWN_MUTATION from '@/modules/shared/graphql/mutations/modalShown.mutation.graphql';
  import MODAL_QUERY from '@/modules/shared/graphql/queries/modal.query.graphql';
  import { popovery } from '@/modules/core/plugins/renderlessBuss/';
  import { PRO_STATUS } from '@/modules/shared/constants/pro.const';
  import { useBasketCoachMark } from '@/modules/shared/composables/basketCoachMark';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import MyMedalsSection from '@/modules/home/components/medals/MyMedalsSection.vue';
  import { JumHeading } from '@blancofoodcoach/kompas';
  import { EXTERNAL_LINKS } from '@/modules/shared/constants/jumbo.const';
  import LinkList from '@/modules/home/components/link-list/LinkList.vue';
  import TryPremium from '@/modules/home/components/try-premium/TryPremium.vue';
  import { hasValidSubscription } from '@/modules/core/utils/user';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import { useElementVisibility } from '@vueuse/core';
  import MyPerformanceGoal from '@/modules/home/components/my-performance-goal/MyPerformanceGoal.vue';
  import ChatIcon from '@/components/chat/ChatIcon.vue';
  import FocusSection from '@/modules/home/components/focus-section/FocusSection.vue';
  import { useRoute } from 'vue-router/composables';
  import AssistantPopover from '@/components/popovers/assistant-popover/AssistantPopover.vue';

  export default defineComponent({
    name: 'FcHome',
    components: {
      FocusSection,
      ChatIcon,
      TryPremium,
      MyMedalsSection,
      DefaultSpacer,
      MyPerformanceGoal,
      JumHeading,
      LinkList,
    },
    setup() {
      const target = ref(null);
      const targetIsVisible = useElementVisibility(target);
      const { apollo } = useVueProxy();
      const route = useRoute();
      const { context } = route.params;
      const { user } = useProfile('network-only');
      const sport = computed(() => user.value?.sportType);
      const showCoachMark = ref(false);
      const showPremiumBlock = computed(() => {
        if (user.value) {
          return !hasValidSubscription(user.value);
        }

        return true;
      });

      const assistant = computed(() => {
        const beginsWith = 'assistant-';
        return user.value?.features
          .find(value => value.startsWith(beginsWith))
          ?.replace(beginsWith, '');
      });

      const checkProTermsModal = async () => {
        const {
          data: { modal },
        } = await apollo.query({
          query: MODAL_QUERY,
          variables: {
            modalName: MODALS.PRO_TERMS_CONDITIONS,
          },
        });

        return user.value?.proStatus === PRO_STATUS.PENDING && modal.shouldBeShown;
      };

      const hasFocus = computed(() => user.value?.playlistId !== null);

      const showProTermsModal = async () => {
        popovery({
          component: ProTermsConditionsPopover,
        });

        await apollo.mutate({
          mutation: MODAL_SHOWN_MUTATION,
          variables: { modalName: MODALS.PRO_TERMS_CONDITIONS },
        });
      };

      onMounted(async () => {
        if (await checkProTermsModal()) await showProTermsModal();
        showCoachMark.value = await useBasketCoachMark().shouldShowCoachMark();

        if (context) {
          setTimeout(() => {
            popovery({
              component: AssistantPopover,
              context,
              assistant: 'conversion',
              scope: 'onboarding',
            });
          }, 250);
        }
      });

      const links = [
        {
          title: 'How does FoodCoach work?',
          external: true,
          url: 'https://www.theathletesfoodcoach.com/how-does-it-work',
        },
        {
          title: 'Insights and Inspiration',
          external: true,
          url: 'https://www.theathletesfoodcoach.com/insights-and-inspiration',
        },
        {
          title: 'Connect to FoodCoach',
          external: true,
          url: 'https://www.theathletesfoodcoach.com/get-support',
        },
        {
          title: 'Grocery list',
          external: false,
          customIcon: 'jum-basket',
          url: { name: 'groceries-products' },
        },
        {
          title: 'Share your feedback',
          external: true,
          url: EXTERNAL_LINKS.FEEDBACK_EMAIL,
        },
      ];

      return {
        user,
        assistant,
        showCoachMark,
        links,
        showPremiumBlock,
        hasFocus,
        target,
        targetIsVisible,
        sport,
      };
    },
  });
</script>

<template>
  <fc-page :class="{ dark: targetIsVisible }">
    <template #top>
      <fc-nav-bar :back-button="false">
        <template v-if="user" #append>
          <router-link
            v-slot="{ navigate }"
            :to="{
              name: 'chat',
              query: {
                assistant,
              },
            }"
            custom
          >
            <chat-icon class="chat-icon" @click.native="navigate" />
          </router-link>
        </template>
      </fc-nav-bar>
    </template>

    <fc-page-content>
      <fc-page-content-breakout>
        <my-performance-goal
          v-if="user"
          ref="target"
          :given-name="user.givenName"
          :middle-name="user.middleName"
          :family-name="user.familyName"
          :event="user.event"
          :calorie-correction="user.calorieCorrection"
        />
      </fc-page-content-breakout>
      <default-spacer s />

      <template v-if="user">
        <focus-section :playlist-id="user.playlistId" />
        <default-spacer />
        <my-medals-section v-if="sport" :sport="sport" />
        <default-spacer />
        <template v-if="showPremiumBlock">
          <try-premium />
        </template>
        <default-spacer />
      </template>

      <jum-heading h4 bold>
        {{ $t('home.headings.extras') }}
      </jum-heading>
      <default-spacer s />
      <link-list :links="links" />
    </fc-page-content>
  </fc-page>
</template>

<style lang="scss" scoped>
  .fc-page-content {
    background-image: url('~@/modules/shared/assets/background-my-goal.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .dark {
    :deep(.fc-page__top),
    :deep(.jum-icon) {
      transition: all 0.1s ease-in-out;
    }

    :deep(.fc-page__top) {
      background-color: #000;
    }

    .chat-icon {
      :deep(path) {
        fill: #fff;
      }
    }

    :deep(.jum-button) {
      &.basket-counter {
        .jum-icon {
          fill: #fff !important;
        }
      }
    }
  }
</style>
