<script>
  import { defineComponent } from 'vue';
  import { JumIcon } from '@blancofoodcoach/kompas';

  export default defineComponent({
    name: 'FcPopup',
    components: {
      JumIcon,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['proceed', 'cancel'],
    setup(props, { emit }) {
      const handleClick = () => {
        emit('proceed');
      };

      const close = () => {
        emit('cancel');
      };

      return {
        handleClick,
        close,
      };
    },
  });
</script>

<template>
  <div class="overlay" :class="{ 'is-open': isOpen }" @click="close">
    <div class="popup" @click.stop>
      <div class="close">
        <jum-icon icon="jum-cross" :size="15" @click.native="close" />
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 9999;

    .popup {
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 16px;
      max-width: 300px;
      overflow: hidden;
      padding: 32px 24px 24px;
      position: relative;
      width: 100%;

      .close {
        cursor: pointer;
        margin-left: auto;
        position: absolute;
        right: 15px;
        top: 15px;
      }

      .jum-heading {
        margin: 0 0 -10px;
      }

      p {
        margin: 0;
      }

      :deep(.jum-button) {
        margin-top: auto;
      }

      img {
        margin: 5px 0;
        min-height: 100px;
        transform: scaleX(-1);
        transition: all 0.4s ease-in-out;
      }
    }

    &.is-open {
      opacity: 0.99;
      visibility: visible;

      img {
        transform: scaleX(-1) rotate3d(0, 1, 0, 180deg);
      }

      .shine {
        &::before {
          animation: shine 2s;
        }
      }
    }
  }
</style>
