<script>
  import { defineComponent, ref, watch } from 'vue';
  import { JumInputRadio, JumTag } from '@blancofoodcoach/kompas';
  import FcInputRadioPanel from '@/modules/shared/components/input-radio-panel/InputRadioPanel.vue';
  import FcFormGroup from '@/modules/shared/components/form-group/FormGroup.vue';

  export default defineComponent({
    components: { FcFormGroup, JumInputRadio, FcInputRadioPanel, JumTag },
    model: {
      prop: 'selectedPlan',
      event: 'update:selectedPlan',
    },
    props: {
      plans: {
        type: Array,
        default: () => [],
      },
      selectedPlan: {
        type: Object,
        default: null,
      },
    },
    setup(props, { emit }) {
      const selectedPlanId = ref(props.selectedPlan?.id);

      watch(selectedPlanId, newPlanId => {
        emit(
          'update:selectedPlan',
          props.plans.find(plan => plan.id === newPlanId)
        );
      });

      const selectPlan = plan => {
        selectedPlanId.value = plan.id;
      };

      return {
        selectedPlanId,
        selectPlan,
      };
    },
  });
</script>

<template>
  <fc-form-group>
    <div v-for="plan in plans" :key="plan.id" class="plan" @click="selectPlan(plan)">
      <fc-input-radio-panel :checked="plan.id === selectedPlanId">
        <jum-input-radio v-model="selectedPlanId" name="plan" :value="plan.id">
          <p>{{ $t(`core.premium.${plan.id}`) }}</p>
          <p class="description">{{ $t(`core.premium.${plan.id}_description`) }}</p>
        </jum-input-radio>

        <div class="price">{{ plan.price }}</div>
      </fc-input-radio-panel>

      <template v-if="plan.tag">
        <jum-tag type="primary">
          {{ plan.tag }}
        </jum-tag>
      </template>
    </div>
  </fc-form-group>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .fc-form-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
  }

  :deep(.radio-panel) {
    .radio-panel__content {
      display: flex;
      justify-content: space-between;
    }
  }

  .plan {
    cursor: pointer;
    position: relative;

    .price {
      flex-shrink: 0;
    }

    p {
      margin-bottom: 0;
    }

    p.description {
      color: $color-gray-darken-20;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .jum-tag.primary {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 10px;
      font-weight: 400;
      height: 17px;
      left: 20px;
      line-height: 10px;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }
  }
</style>
