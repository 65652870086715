<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PageContentBreakout',
    props: {
      side: {
        type: String,
        default: 'both',
        validator: side => ['both', 'left', 'right'].includes(side),
      },
    },
  });
</script>

<template>
  <div class="fc-page-content-breakout" :class="{ [side]: side }">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-page-content-breakout {
    &.both {
      margin-left: $page-padding * -1;
      margin-right: $page-padding * -1;
      width: calc(100% + #{$page-padding * 2});
    }

    &.left,
    &.right {
      width: calc(100% + #{$page-padding});
    }

    &.left {
      margin-left: $page-padding * -1;
    }

    &.right {
      margin-right: $page-padding * -1;
    }
  }
</style>
