<script>
  import { JumButton, JumIcon } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';
  import FcTipBox from '@/components/library/tip-box/tip-box.vue';

  export default defineComponent({
    name: 'FcTipMenu',
    components: {
      FcTipBox,
      JumIcon,
      JumButton,
    },
    props: {
      buttons: {
        type: Array,
        required: true,
      },
      x: {
        type: Number,
        default: 0,
      },
      y: {
        type: Number,
        default: 0,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const onClick = action => {
        emit('close');
        action();
      };

      return {
        onClick,
      };
    },
  });
</script>

<template>
  <fc-tip-box class="tip-menu" :padded="false" :full-width="fullWidth" :style="{ top: `${y}px` }">
    <template v-for="option in buttons">
      <jum-button
        v-if="option.condition !== undefined ? option.condition : true"
        :key="option.label"
        tertiary
        @click="() => onClick(option.action)"
      >
        <jum-icon v-if="option.icon" :icon="option.icon" size="20" left />
        <span>{{ option.label }}</span>
      </jum-button>
    </template>
  </fc-tip-box>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  button.jum-button.tertiary {
    display: flex;
    flex-direction: column;
    padding: $spacing-m ($spacing-m + $spacing-s);
    white-space: nowrap;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:only-child {
      border-radius: 8px;
    }

    + .jum-button {
      border-top: 1px solid $color-gray-lighten-60;
    }
  }
</style>
