import useGroceryDates from '@/modules/shared/composables/groceryDates';
import apollo from '@/modules/shared/graphql/apollo.config';
import DAY_PLANS_QUERY from '@/modules/planner/graphql/queries/dayPlans.query.graphql';
import { computed, reactive, readonly } from 'vue';
import { ScaledDayMapper } from '@blancofoodcoach/content-scaling';
import GET_DAY_TEMPLATES_QUERY from '@/modules/planner/graphql/queries/dayTemplates.query.graphql';

const groceries = reactive({
  list: [],
  dates: [],
});

const getScaledDays = async (startDate, endDate) => {
  if (!startDate && !endDate) {
    return [];
  }
  const {
    data: { dayPlans },
  } = await apollo.defaultClient.query({
    query: DAY_PLANS_QUERY,
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    data: { dayTemplates },
  } = await apollo.defaultClient.query({
    query: GET_DAY_TEMPLATES_QUERY,
    variables: {
      input: {
        startDate,
        endDate,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const dayTemplateByDate = dayTemplates.mapBy(dayTemplate => dayTemplate.date);

  return dayPlans.map(dayPlan =>
    ScaledDayMapper.instance.fromDayPlan(dayPlan, dayTemplateByDate.get(dayPlan.date) || {})
  );
};

const mergeIngredients = (scaledDays = []) =>
  scaledDays
    .filter(scaledDay => !!scaledDay?.meals)
    .flatMap(scaledDay => scaledDay.meals)
    .map(meal => meal.flatten())
    .flatMap(meal => meal.ingredients)
    .mergeBy(scaledIngredient => scaledIngredient.id);

const getScaledIngredients = scaledDays => {
  if (!scaledDays) {
    return [];
  }

  const scaledIngredients = mergeIngredients(scaledDays);

  return scaledIngredients.map(scaledIngredient => ({
    amount: scaledIngredient.amount.toDecimalPlaces(1).toNumber(),
    id: scaledIngredient.id,
    leafId: scaledIngredient.leafId,
    macros: {
      calories: scaledIngredient.macros.calories.toNumber(),
      carbs: scaledIngredient.macros.carbs.toNumber(),
      fat: scaledIngredient.macros.fat.toNumber(),
      fiber: scaledIngredient.macros.fiber.toNumber(),
      protein: scaledIngredient.macros.protein.toNumber(),
    },
    name: scaledIngredient.name,
    milliliterBased: scaledIngredient.milliliterBased,
    weight: scaledIngredient.weight.toNumber(),
    unitOfMeasure: scaledIngredient.unitOfMeasure,
  }));
};

const fetch = async () => {
  const groceryDates = await useGroceryDates();
  const dates = groceryDates.flatMap(({ selected, date }) => (selected ? date : []));
  const startDate = dates.at(0);
  const endDate = dates.at(-1);
  const scaledDays = await getScaledDays(startDate, endDate);

  groceries.dates = groceryDates;
  groceries.list = getScaledIngredients(scaledDays);
};

const count = computed(() => groceries.list.length);

export const groceriesStore = readonly({
  groceries,
  count,
  fetch,
});
