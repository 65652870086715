import messages from './messages/nl/groceries.nl';
import messagesEn from './messages/en/groceries.en';
import { registerRoutes } from './routes/groceries.routes';

export const groceriesModule = (
  sharedModule,
  { router },
  { guards: { onboardingGuard } },
  i18nModule
) => ({
  install() {
    i18nModule.setTranslations({ groceries: { ...messages } }, 'nl');
    i18nModule.setTranslations({ groceries: { ...messagesEn } }, 'en');
    registerRoutes(router, sharedModule.utils.guards([onboardingGuard]));
  },
});
