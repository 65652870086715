<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcReference',
    props: {
      reference: {
        type: Object,
        required: true,
      },
    },
    computed: {
      referenceIsLink({ reference }) {
        return reference.type === 'link';
      },
    },
    methods: {
      getComponentForReferenceType: type => ({ bold: 'strong', link: 'a', underline: 'u' })[type],
    },
  });
</script>

<template>
  <component
    :is="getComponentForReferenceType(reference.type)"
    :href="referenceIsLink ? reference.url : null"
    @click.prevent="
      referenceIsLink ? $inAppBrowser({ url: reference.url, text: reference.text }) : null
    "
  >
    <slot></slot>
  </component>
</template>
