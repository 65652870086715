import Home from '@/modules/home/pages/home/Home.vue';

export const TAB_ROUTE = {
  name: 'home',
  path: '/home',
  component: Home,
  meta: {
    noPayWall: true,
  },
};

export const registerRoutes = router => {
  const routes = [
    {
      name: 'playlist-detail',
      path: '/playlist/:slug',
      component: () => import('@/modules/home/pages/playlist-detail/PlaylistDetail.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'blog-detail',
      path: '/blog/:slug',
      component: () => import('@/modules/home/pages/blog-detail/BlogDetail.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'challenge-detail',
      path: '/playlist/:slug/challenge/:challengeSlug',
      component: () => import('@/modules/home/pages/challenge-detail/ChallengeDetailPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    // Temporarily added to prevent breaking old flow
    {
      name: 'challenge-detail-no-playlist',
      path: '/challenge/:uuid',
      component: () =>
        import(
          '@/modules/home/pages/challenge-detail-no-playlist/ChallengeDetailNoPlaylistPage.vue'
        ),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'doing-detail',
      path: '/challenge/:challengeUuid/doing/:doingUuid',
      component: () => import('@/modules/home/pages/doing-detail/DoingDetailPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'doing-detail-playlist',
      path: '/playlist/:playlistUuid/challenge/:challengeUuid/doing/:doingUuid',
      component: () => import('@/modules/home/pages/doing-detail/DoingDetailPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'video-detail',
      path: '/playlist/:slug/video/:videoSlug',
      component: () => import('@/modules/home/pages/video-detail/VideoDetailPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'challenge-video-detail-page',
      path: '/challenge/:challengeUuid/video/:videoUuid',
      component: () => import('@/pages/challenge/video/ChallengeVideoDetailPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'video-no-playlist',
      path: '/video/:videoSlug',
      component: () =>
        import('@/modules/home/pages/video-detail-no-playlist/VideoDetailNoPlaylistPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
    {
      name: 'carbs-per-hour-calculator',
      path: '/carbs-per-hour-calculator',
      component: () =>
        import('@/modules/home/pages/carbs-per-hour-calculator/CarbsPerHourCalculatorPage.vue'),
      meta: {
        noPayWall: true,
        savePosition: true,
      },
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
