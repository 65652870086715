import { useVueProxy } from '@/composables/useVueProxy';
import addPlaylistProgressMutation from '@/modules/shared/graphql/mutations/addPlaylistProgress.mutation.graphql';
import resetPlaylistProgressMutation from '@/modules/shared/graphql/mutations/resetPlaylistProgress.mutation.graphql';
import getPlaylistProgressQuery from '@/modules/shared/graphql/queries/getPlaylistProgressQuery.query.graphql';
import { computed, onBeforeMount, ref } from 'vue';
import { mapPlaylist } from '@/util/gamification/playlists';
import http from '@/modules/shared/auth/services/http/strapiHttp.service';

export const usePlaylist = (uuid = null, preview = false) => {
  const { apollo } = useVueProxy();
  const playlistContent = ref(null);
  const playlistProgress = ref(null);
  const playlist = computed(() => {
    if (!playlistContent.value) {
      return null;
    }

    let opened = false;
    return {
      ...playlistContent.value,
      phases: playlistContent.value.phases.map(phase => {
        const isCompleted = phase.links?.every(item =>
          playlistProgress.value?.items.includes(item.uuid)
        );
        let isOpen = false;
        if (!isCompleted && !opened) {
          isOpen = true;
          opened = true;
        }

        return {
          ...phase,
          isOpen,
          isCompleted,
          items: phase.items.map(item => {
            if (item.tag === 'challenge') {
              return {
                ...item,
                meta: {
                  ...item.meta,
                  steps: item.meta?.steps?.map(step => ({
                    ...step,
                    links: step.links
                      .filter(link => link !== null)
                      .map(link => ({
                        ...link,
                        completed: playlistProgress.value?.items.includes(link.uuid) ?? false,
                      })),
                  })),
                },
              };
            }

            return item;
          }),
          links: phase.links.map(link => ({
            ...link,
            completed: playlistProgress.value?.items.includes(link.uuid) ?? false,
          })),
        };
      }),
    };
  });

  const addPlaylistProgress = async (playlistId, itemId) => {
    await apollo.mutate({
      mutation: addPlaylistProgressMutation,
      variables: {
        playlistId,
        itemId,
      },
    });
  };

  const resetPlaylistProgress = async playlistId => {
    await apollo.mutate({
      mutation: resetPlaylistProgressMutation,
      variables: {
        playlistId,
      },
    });
  };

  const getPlaylistProgress = async playlistId => {
    const response = await apollo.query({
      query: getPlaylistProgressQuery,
      variables: {
        playlistId,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data?.playlist;
  };

  const refetchPlaylistProgress = async () => {
    playlistProgress.value = await getPlaylistProgress(uuid);
  };

  onBeforeMount(async () => {
    if (!uuid) {
      return;
    }

    const publicationState = preview ? 'preview' : 'live';

    const response = await http.get(
      `/playlists?populate=deep,8&filters[uuid][$eq]=${uuid}&publicationState=${publicationState}`
    );

    [playlistContent.value] = response.data.data.map(mapPlaylist);
    await refetchPlaylistProgress();
  });

  return {
    addPlaylistProgress,
    getPlaylistProgress,
    resetPlaylistProgress,
    refetchPlaylistProgress,
    playlistProgress,
    playlist,
  };
};
