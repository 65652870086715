export default {
  add: 'Yes, add my training',
  blogs: 'Read all the blogs',
  boostMeal: 'Tune meal',
  calculateEnergyNeed: 'Calculate your energy needs',
  deleteRecipe: 'Delete own recipe',
  edit: 'Change',
  editInfo: 'Change data',
  addTraining: 'Add this training',
  editTraining: 'Change this training',
  next: 'Next',
  removeTraining: 'Delete this training',
  save: 'Save',
  saveAsRecipe: 'Save as recipe',
  saveFtp: 'Continue to training',
  select: 'Select',
  pairActivity: 'Pair to planned training',
  deleteActivity: 'Delete activity',
  unpairActivity: 'Unpair from planned training',
  planMeals: 'Plan this meal',
  updateActuals: 'Change training data',
};
