import APP_OPEN_MUTATION from '@/modules/shared/graphql/mutations/appOpen.mutation.graphql';
import APP_OPENS_QUERY from '@/modules/shared/graphql/queries/appOpens.query.graphql';
import http from '@/modules/shared/auth/services/http/http.service';
import { PROCESS } from '@/modules/shared/constants/env.const';
import { App as CapApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import REFRESH_TOKENS_MUTATION from '@/modules/core/graphql/mutations/refreshTokens.mutation.graphql';
import { useProfile } from '@/modules/planner/composables/useProfile';

export const appOpensMixin = {
  apollo: {
    appOpens: {
      query: APP_OPENS_QUERY,
      update({ appOpens }) {
        this.registerAppOpen();
        return appOpens;
      },
      skip() {
        return this.appOpens !== undefined;
      },
    },
  },
  methods: {
    async registerAppOpen() {
      await this.$apollo.mutate({
        mutation: APP_OPEN_MUTATION,
      });
    },
  },
  async beforeCreate() {
    if (Capacitor.isNativePlatform()) {
      await CapApp.addListener('resume', async () => {
        try {
          const result = await this.$apollo.mutate({ mutation: REFRESH_TOKENS_MUTATION });
          if (result?.data?.refreshTokens === true) {
            await useProfile('network-only').fetch();
          }
        } catch (error) {
          await this.$router.push({ name: 'auth-login' });
        }
        try {
          const response = await http.get(`${process.env.VUE_APP_GATEWAY}/version.json`);
          if (response?.status === 200) {
            if (PROCESS.PACKAGE_VERSION !== response.data.version) {
              console.log(
                `New version available. CURRENT: ${PROCESS.PACKAGE_VERSION}, LATEST ${response.data.version}`
              );
              // window.location.reload();
            }
          }
        } catch (error) {
          console.log('Error checking for new version', error);
          // window.location.reload();
        }
      });
    }
  },
};
