<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcActionBar',
    props: {
      horizontal: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<template>
  <div class="fc-action-bar" :class="{ horizontal }">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-action-bar {
    border-top: 1px solid $color-gray-lighten-80;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    gap: $spacing-s;
    padding: $spacing-m;
    position: relative;

    &.horizontal {
      flex-direction: row;

      :deep(.jum-button) {
        flex-grow: 1;
      }
    }

    :deep(.jum-button) {
      justify-content: center;
    }
  }
</style>
