export default {
  energyCalculationSaved: 'Energieberekening is opgeslagen',
  privacyStatementSaved: 'Je privacy voorkeur is opgeslagen',
  preferredLanguageSaved: 'Taal opgeslagen',
  eventSaved: 'Jouw event is opgeslagen',
  eventRemoved: 'Jouw event is verwijderd',
  ProductDatabaseSaved: 'Product database is opgeslagen',
  privacyStatementFailed: 'Het opslaan van je privacy voorkeur is mislukt',
  givenNameSaved: 'Voornaam is aangepast',
  middleNameSaved: 'Tussenvoegsel is aangepast',
  familyNameSaved: 'Achternaam is aangepast',
  fieldSaved: '{field} is opgeslagen',
  devices: {
    garmin: {
      connectSuccess: 'Verbinding met Garmin Connect tot stand gebracht',
      connectFailed: 'Verbindingspoging met Garmin Connect mislukt',
      disconnectSuccess: 'Verbinding met Garmin Connect succesvol verbroken',
      disconnectFailed: 'Verbreken van de verbinding met Garmin Connect mislukt',
    },
    wahoo: {
      connectSuccess: 'Verbinding met Wahoo Connect tot stand gebracht',
      connectFailed: 'Verbindingspoging met Wahoo Connect mislukt',
      disconnectSuccess: 'Verbinding met Wahoo Connect succesvol verbroken',
      disconnectFailed: 'Verbreken van de verbinding met Wahoo Connect mislukt',
    },
    strava: {
      connectSuccess: 'Verbinding met Strava tot stand gebracht',
      connectFailed: 'Verbindingspoging met Strava mislukt',
      disconnectSuccess: 'Verbinding met Strava succesvol verbroken',
      disconnectFailed: 'Verbreken van de verbinding met Strava mislukt',
    },
    trainingpeaks: {
      connectSuccess: 'Verbinding met TrainingPeaks tot stand gebracht',
      connectFailed: 'Verbindingspoging met TrainingPeaks mislukt',
      disconnectSuccess: 'Verbinding met TrainingPeaks succesvol verbroken',
      disconnectFailed: 'Verbreken van de verbinding met TrainingPeaks mislukt',
    },
    join: {
      dialog: 'Gebruik de JOIN app om te verbinden met FoodCoach',
      approveFailed:
        'Er is iets misgegaan bij het goedkeuren van de verbinding, probeer het later opnieuw',
      disconnectSuccess: 'Verbinding met JOIN succesvol verbroken',
      disconnectFailed: 'Verbreken van de verbinding met JOIN mislukt',
      confirmText: 'Sluiten',
    },
    intervals: {
      connectSuccess: 'Verbinding met Intervals.icu tot stand gebracht',
      connectFailed: 'Verbindingspoging met Intervals.icu mislukt',
      disconnectSuccess: 'Verbinding met Intervals.icu succesvol verbroken',
      disconnectFailed: 'Verbreken van de verbinding met Intervals.icu mislukt',
    },
  },
};
