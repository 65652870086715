<script>
  import { JumHeading } from '@blancofoodcoach/kompas';
  import FcReference from '@/modules/shared/components/reference/Reference';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcPrivacyStatementSections',
    components: { JumHeading, FcReference },
    props: {
      section: {
        type: Object,
        required: true,
      },
      headingLevel: {
        type: Number,
        default: 4,
      },
      i18nPathBase: {
        type: String,
        default: undefined,
      },
    },
    computed: {
      pathBase: vm => `${vm.i18nPathBase ? `${vm.i18nPathBase}.` : ''}`,
      headingType: vm => (vm.headingLevel > 6 ? 'h6' : `h${vm.headingLevel}`),
    },
  });
</script>

<template>
  <div>
    <jum-heading :[headingType]="true" :bold="headingLevel > 5">
      {{ $t(`${pathBase}title`) }}
    </jum-heading>

    <i18n
      v-for="(paragraph, paragraphKey) in section.paragraphs"
      :key="`paragraph-${paragraphKey}`"
      :path="`${pathBase}paragraphs[${paragraphKey}].text`"
      tag="p"
    >
      <template v-for="(reference, referenceKey) in paragraph.references">
        <fc-reference :key="`reference-${referenceKey}`" :reference="reference">
          {{ $t(`${pathBase}paragraphs[${paragraphKey}].references[${referenceKey}].text`) }}
        </fc-reference>
      </template>
    </i18n>

    <fc-privacy-statement-sections
      v-for="(subSection, subSectionKey) in section.sections"
      :key="subSectionKey"
      :section="subSection"
      :i18n-path-base="`${pathBase}sections[${subSectionKey}]`"
      :heading-level="headingLevel + 1"
    />
  </div>
</template>
