<script setup lang="ts">
  import FcPopup from '@/components/popup/Popup.vue';
  import { JumButton, JumHeading } from '@blancofoodcoach/kompas';

  defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['close']);

  const closePopup = () => {
    emit('close');
  };
</script>

<template>
  <fc-popup :is-open="isOpen" class="popup" @cancel="closePopup">
    <jum-heading bold h5 class="margin-bottom-xs">
      {{ $t('popupTitle') }}
    </jum-heading>

    <jum-button secondary @click="closePopup">
      {{ $t('closePopupLabel') }}
    </jum-button>
  </fc-popup>
</template>

<style scoped lang="scss">
  :deep(.jum-button) {
    justify-content: center;
  }
</style>

<i18n>
{
  "en": {
    "closePopupLabel": "Ok",
    "popupTitle": "Not all tasks are checked, please do did first to finish this doing"
  },
  "nl": {
    "closePopupLabel": "Ok",
    "popupTitle": "Niet alle taken zijn afgevinkt, vink deze eerst af om deze actie af te ronden"
  }
}
</i18n>
