<script setup>
  import FcTag from '@/modules/home/components/FcTag.vue';
  import { useInAppBrowser } from '@/modules/core/composables/useInAppBrowser';
  import { AppLauncher } from '@capacitor/app-launcher';
  import { computed } from 'vue';
  import { useRouter } from 'vue-router/composables';
  import { JumIcon } from '@blancofoodcoach/kompas';
  import { Device } from '@capacitor/device';

  const props = defineProps({
    link: {
      type: Object,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    showFocus: {
      type: Boolean,
      default: false,
    },
  });

  const { open } = useInAppBrowser();
  const router = useRouter();

  const tagTypes = [
    'perform',
    'recover',
    'protect',
    'health',
    'coach',
    'video',
    'challenge',
    'learning',
    'doing',
  ];
  const isDisabled = computed(() => !props.link.url);
  const isCompleted = computed(() => props.link?.completed ?? false);
  const isLocked = computed(() => props.locked ?? false);
  const isFocus = computed(() => props.showFocus && !isCompleted.value && !isLocked.value);
  const handleClick = async () => {
    if (props.link.isExternal) {
      if (props.link.url.includes('https://youtu.be')) {
        const { platform } = await Device.getInfo();

        if (platform === 'android') {
          const { value: canOpenUrl } = await AppLauncher.canOpenUrl({
            url: 'com.google.android.youtube',
          });

          if (canOpenUrl) {
            await AppLauncher.openUrl({ url: props.link.url });
            return;
          }
        } else if (platform === 'ios') {
          const { value: canOpenUrl } = await AppLauncher.canOpenUrl({
            url: props.link.url,
          });

          if (canOpenUrl) {
            await AppLauncher.openUrl({ url: props.link.url });
            return;
          }
        }
      }
      await open(props.link.url);
      return;
    }
    await router.push(props.link.url);
  };
</script>

<template>
  <div
    class="link-card-list-item"
    :class="{
      disabled: isDisabled,
      completed: isCompleted,
      locked: isLocked,
      focus: isFocus,
    }"
    @click="handleClick"
  >
    <div class="link-card-list-item__image">
      <img :src="link.img" alt="Image for link" />
    </div>

    <div class="link-card-list-item__body">
      <span v-if="link.tag">
        <fc-tag mini :[link.tag.type]="tagTypes.includes(link.tag.type)" :custom="link.tag.text" />
      </span>

      <span class="title">
        {{ link.title }}
      </span>
    </div>

    <div class="completed-overlay">
      <jum-icon icon="jum-check" :size="25" />
    </div>

    <div class="locked-overlay">
      <jum-icon icon="jum-lock" :size="30" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  $out-of-focus-opacity: 0.25;

  .link-card-list-item {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    max-height: 80px;
    overflow: hidden;
    scroll-margin: 16px;
    transition: border 0.25s ease-in-out;

    &__image {
      display: flex;
      flex-shrink: 0;
      height: 80px;
      transition: opacity 0.25s ease-in-out;
      width: 80px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &__body {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      padding: 0 16px;
      transition: opacity 0.25s ease-in-out;

      span.title {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: 0.875rem;
        font-weight: 300;
        height: 0.875rem * 1.3 * 2;
        -webkit-line-clamp: 2;
        line-height: 0.875rem * 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.focus {
      border: 1px solid #000;
    }

    &.disabled {
      pointer-events: none;

      .link-card-list-item__image,
      .link-card-list-item__body {
        opacity: $out-of-focus-opacity;
      }
    }

    .completed-overlay,
    .locked-overlay {
      display: none;
    }

    &.completed,
    &.locked {
      position: relative;

      .link-card-list-item__image,
      .link-card-list-item__body {
        opacity: $out-of-focus-opacity;
      }

      .completed-overlay,
      .locked-overlay {
        align-items: center;
        content: '';
        inset: 0;
        position: absolute;

        .jum-icon {
          fill: #bebebe;
          left: 40px;
          position: relative;
          transform: translateX(-50%);
        }
      }
    }

    &.completed {
      .completed-overlay {
        display: flex;
      }
    }

    &.locked {
      pointer-events: none;

      .locked-overlay {
        display: flex;
      }
    }

    &:not(.disabled):hover {
      cursor: pointer;
    }
  }
</style>
