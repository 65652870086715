export default {
  buttons: {
    continuePlanning: 'Continue planning',
    shareGroceries: 'Share grocery list',
    seeRecipes: 'Discover recipes',
  },
  headings: {
    emptyGroceryList: 'Your grocery list is empty',
    yourGroceries: 'Your groceries',
  },
  labels: {
    clearList: 'Delete all recipes',
  },
  info: {
    addRecipesToYourList: 'Discover or search for recipes and click “Add to shopping list”',
    emptyGroceryList:
      'Plan meals and select the days for which you want to create a shopping list.',
    groceryList: {
      intro: "We've selected for you the days on which you have planned your meals.",
      explanation: 'You can also deselect these days. We automatically adjust your shopping list.',
    },
    participants: '{n} Participant | {n} Participants',
    pieces: '{n} piece | {n} pieces',
  },
  share: {
    title: 'Your grocery list',
    dialogTitle: 'Share your grocery list',
  },
  modals: {
    clearList: {
      title: 'Clear my plan',
      message: 'Are you sure you want to clear the planned meals?',
      cancelText: 'No, cancel',
      confirmText: 'Yes, clear',
    },
  },
  links: {
    recipes: 'Recipes',
    products: 'Products',
  },
  other: 'Other',
};
