export default {
  buttons: {
    continuePlanning: 'Ga verder met plannen',
    seeRecipes: 'Ontdek recepten',
  },
  headings: {
    emptyGroceryList: 'Je boodschappenlijstje is leeg',
    yourGroceries: 'Je boodschappen',
  },
  labels: {
    clearList: 'Alle recepten verwijderen',
  },
  info: {
    addRecipesToYourList: 'Ontdek of zoek recepten en klik op “Toevoegen aan boodschappenlijstje”',
    emptyGroceryList:
      'Plan maaltijden in en selecteer de dagen waarvoor je een boodschappenlijstje wilt maken.',
    groceryList: {
      intro: 'We hebben de dagen waarop je je maaltijden hebt ingepland voor je geselecteerd.',
      explanation:
        'Je kan deze dagen ook deselecteren. Wij passen je boodschappenlijstje automatisch aan.',
    },
    participants: '{n} persoon | {n} personen',
    pieces: '{n} stuk | {n} stuks',
  },
  share: {
    title: 'Je boodschappenlijstje',
    dialogTitle: 'Deel je boodschappenlijstje',
  },
  modals: {
    clearList: {
      title: 'Planner leegmaken',
      message: 'Weet je zeker dat je de geplande maaltijden wilt leegmaken?',
      cancelText: 'Annuleren',
      confirmText: 'Leegmaken',
    },
  },
  links: {
    recipes: 'Recepten',
    products: 'Producten',
  },
  other: 'Overig',
};
