<script>
  import Vue, { computed, defineComponent, onBeforeMount, ref } from 'vue';
  import { JumButton, JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import PlanSelector from '@/modules/core/components/plan-selector/PlanSelector.vue';
  import { Purchases } from '@revenuecat/purchases-capacitor';
  import { useI18n } from '@/modules/core/composables/useI18n';
  import { Capacitor } from '@capacitor/core';
  import FcNote from '@/modules/shared/components/note/Note.vue';
  import REFRESH_TOKENS_MUTATION from '@/modules/core/graphql/mutations/refreshTokens.mutation.graphql';
  import SYNC_SUBSCRIPTION_MUTATION from '@/modules/core/graphql/mutations/syncSubscription.mutation.graphql';
  import { useNotification } from '@/modules/core/composables/useNotification';
  import { useRouter } from 'vue-router/composables';
  import { useAsyncQuery } from '@/modules/shared/utils/apollo.util';
  import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';
  import * as Sentry from '@sentry/vue';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { setRevenueCatCustomer } from '@/util/revenueCat';

  export default defineComponent({
    name: 'PremiumPage',
    components: {
      FcNote,
      PlanSelector,
      DefaultSpacer,
      JumHeading,
      JumButton,
      JumIcon,
    },
    setup: () => {
      const { notifySuccess, notifyFailed } = useNotification();
      const isUpdatingSubscription = ref(false);
      const { apollo } = useVueProxy();
      const { t } = useI18n();
      const router = useRouter();
      const isLoading = ref(true);
      const selectedPlan = ref(null);
      const offerings = ref(null);
      const user = ref(null);
      const activeSubscriptions = ref(null);
      const isSubscriptionInSync = computed(
        () => activeSubscriptions.value.length > 0 && user.value.premium
      );
      const restore = () => {
        apollo
          .mutate({
            mutation: SYNC_SUBSCRIPTION_MUTATION,
          })
          .then(({ data }) => {
            if (data?.syncSubscription) {
              notifySuccess(t('core.premium.restored'));
              setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                router.push({ name: 'home' }, () => location.reload());
              }, 1000);
            } else {
              notifyFailed(t('core.premium.restoreFailed'));
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            notifyFailed(t('core.premium.restoreFailed'));
          });
      };
      const availablePackages = computed(() => offerings.value?.current.availablePackages);
      const isNativePlatform = Capacitor.isNativePlatform();
      const hasPlugin = Capacitor.isPluginAvailable('Purchases');
      const plans = computed(
        () =>
          availablePackages.value?.map(offering => ({
            id: offering.identifier,
            name: offering.product.title,
            price: offering.product.priceString,
            tag: offering.packageType === 'ANNUAL' ? t('core.premium.earlyBirdTag') : null,
            packageType: offering.packageType,
          })) ?? []
      );
      const hasPlans = computed(() => plans.value.length > 0);
      const yearlyPrice = computed(
        () => plans.value.find(plan => plan.packageType === 'ANNUAL')?.price || ''
      );
      const monthlyPrice = computed(
        () => plans.value.find(plan => plan.packageType === 'MONTHLY')?.price || ''
      );

      onBeforeMount(async () => {
        if (isNativePlatform && hasPlugin) {
          try {
            const { result } = await useAsyncQuery(GET_USER_PROFILE_QUERY);
            user.value = result.value?.getUserProfile.user;
            await setRevenueCatCustomer(user.value);
            const { customerInfo } = await Purchases.getCustomerInfo();
            activeSubscriptions.value = customerInfo?.activeSubscriptions ?? [];

            offerings.value = await Purchases.getOfferings();
            [selectedPlan.value] = plans.value;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            Sentry.captureException(error);
          }
        }

        isLoading.value = false;
      });

      let retries = 0;
      const retryTimeouts = {
        1: 1000,
        2: 3000,
        3: 6000,
      };
      const refreshTokens = async () =>
        apollo
          .mutate({
            mutation: REFRESH_TOKENS_MUTATION,
          })
          .then(() => {
            const authorized = Vue.$cookies.get('authorized');

            if (authorized === 'true') {
              isUpdatingSubscription.value = false;
              notifySuccess(t('core.premium.success'));
              setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                router.push({ name: 'home' }, () => location.reload());
              }, 1000);
            } else if (retries < 5) {
              retries += 1;
              setTimeout(() => refreshTokens(), retryTimeouts[retries]);
            } else {
              isUpdatingSubscription.value = false;
              notifyFailed(t('core.premium.statusUpdateFailed'));
            }
          });
      const subscribe = async () => {
        try {
          const purchaseResult = await Purchases.purchasePackage({
            aPackage: availablePackages.value.find(
              offering => offering.identifier === selectedPlan.value.id
            ),
          });

          if (purchaseResult) {
            isUpdatingSubscription.value = true;
            await refreshTokens();
          } else {
            notifyFailed(t('core.premium.paymentFailed'));
          }
        } catch (error) {
          if (error.message.includes('Purchase was cancelled')) {
            notifyFailed(t('core.premium.paymentCancelled'));
            return;
          }

          // eslint-disable-next-line no-console
          console.log(error);
          Sentry.captureException(error);
          notifyFailed(t('core.premium.paymentFailed'));
        }
      };

      return {
        hasPlans,
        plans,
        yearlyPrice,
        monthlyPrice,
        selectedPlan,
        isLoading,
        subscribe,
        isNativePlatform,
        isUpdatingSubscription,
        hasPlugin,
        isSubscriptionInSync,
        restore,
      };
    },
  });
</script>

<template>
  <fc-page>
    <template #top>
      <fc-nav-bar />
    </template>

    <fc-page-content v-if="!isLoading">
      <div v-if="isUpdatingSubscription" class="is-paying-overlay">
        <fc-content-spinner />
        <div>
          {{ $t('core.premium.updatingSubscription') }}
        </div>
      </div>

      <img
        :src="require('@/assets/images/foodcoach-premium.webp')"
        alt="Image for premium page"
        class="image margin-bottom-m"
      />

      <jum-heading h3 bold class="margin-bottom-m">
        {{ $t('core.premium.title') }}
      </jum-heading>

      <ul class="benefits-list">
        <li v-for="(index, key) in $t('core.premium.benefits')" :key="`benefit-${index}`">
          <jum-icon icon="fc-check" />
          <p>
            {{
              $t(`core.premium.benefits[${key}]`, {
                yearPrice: yearlyPrice,
                monthPrice: monthlyPrice,
              })
            }}
          </p>
        </li>

        <li>
          {{ $t('core.premium.postBenefitsLabel') }}
        </li>
      </ul>

      <default-spacer m />

      <template v-if="isNativePlatform">
        <template v-if="!hasPlugin">
          <fc-note>
            {{ $t('core.premium.updateToLatest') }}
          </fc-note>
        </template>

        <template v-else-if="hasPlugin && hasPlans">
          <jum-heading h4 bold>
            {{ $t('core.premium.chooseYourPaymentTitle') }}
          </jum-heading>

          <default-spacer s />

          <plan-selector v-model="selectedPlan" :plans="plans" />

          <template v-if="!isSubscriptionInSync">
            <jum-button block tertiary @click="restore">
              {{ $t('core.premium.restore') }}
            </jum-button>
          </template>
        </template>
        <template v-else>
          <fc-note>
            {{ $t('core.premium.notAvailable') }}
          </fc-note>
        </template>
      </template>

      <template v-else>
        <fc-note>
          {{ $t('core.premium.useApp') }}
        </fc-note>
      </template>
    </fc-page-content>

    <template #bottom>
      <fc-action-bar>
        <jum-button
          block
          :disabled="!isNativePlatform || !hasPlugin || !hasPlans || isUpdatingSubscription"
          @click="subscribe"
        >
          {{ $t('core.premium.subscribe') }}
        </jum-button>

        <router-link :to="{ name: 'home' }" :disabled="isUpdatingSubscription">
          <jum-button tertiary block class="no-underline">
            {{ $t('core.premium.lookAround') }}
          </jum-button>
        </router-link>
      </fc-action-bar>
    </template>
  </fc-page>
</template>

<style scoped lang="scss">
  img {
    border-radius: 16px;
    height: 100%;
    margin-top: -16px;
    object-fit: cover;
    width: 100%;
  }

  :deep(a) {
    text-decoration: none !important;
  }

  .benefits-list {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    gap: 8px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      line-height: 16px;

      svg {
        flex: 1;
      }

      p {
        flex: 11;
        margin-bottom: 0;
      }
    }
  }

  .is-paying-overlay {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    gap: 8px;
    inset: 0;
    justify-content: center;
    position: fixed;
    z-index: 100;

    div {
      max-width: 80vw;
      text-align: center;
      white-space: pre-line;
    }
  }
</style>
