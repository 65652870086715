import { onBeforeMount, ref } from 'vue';
import http from '@/modules/shared/auth/services/http/chatHttp.service';
import qs from 'qs';

export const useOpenAIAssistant = (assistant, scope, createThreadContext) => {
  const thread = ref({ messages: [] });
  const isResponding = ref(false);
  const isChatUnavailable = ref(false);
  const isLoading = ref(true);
  const historyCompleted = ref(true);

  const handleNewMessages = function (response) {
    historyCompleted.value =
      response.data.thread.messages.length === 0 ||
      response.data.thread.messages.length < response.data.thread.limit;

    const combinedArray = [...new Set([...thread.value.messages, ...response.data.thread.messages])]
      .filter(message => message.id !== 'temp')
      .filter(
        (message, index, messages) => messages.find(value => value.id === message.id) === message
      );

    combinedArray.sort((a, b) => b.createdAtRaw - a.createdAtRaw);

    thread.value.messages = combinedArray;
  };

  onBeforeMount(async () => {
    const query = qs.stringify({
      assistant,
      scope,
    });
    isResponding.value = true;
    const response = await http.post(`/thread?${query}`, {
      createThreadContext,
    });
    handleNewMessages(response);
    isResponding.value = false;
    isLoading.value = false;
  });

  const addMessage = async message => {
    if (!message) {
      throw new Error('Message is required');
    }
    isResponding.value = true;

    thread.value.messages.unshift({
      id: 'temp',
      sender: 'user',
      createdAtRaw: Date.now() / 1000,
      content: [
        {
          type: 'text',
          text: {
            value: message.trim(),
          },
        },
      ],
    });
    const query = qs.stringify({
      assistant,
      scope,
    });

    try {
      const response = await http.post(`/thread/messages?${query}`, {
        message,
      });

      handleNewMessages(response);

      isResponding.value = false;
    } catch (error) {
      try {
        const response = await http.post(`/thread?${query}`);
        handleNewMessages(response);
        isResponding.value = false;
      } catch (error2) {
        isChatUnavailable.value = true;
      }
    }
  };

  const addMessageJson = async json => {
    isResponding.value = true;
    const query = qs.stringify({
      assistant,
      scope,
    });

    const response = await http.post(`/thread/messages?${query}`, {
      message: JSON.stringify(json),
    });
    handleNewMessages(response);

    isResponding.value = false;
  };

  const deleteChatHistory = async () => {
    const query = qs.stringify({
      assistant,
      scope,
    });
    const axiosResponse = await http.delete(`/thread?${query}`);
    if (axiosResponse.status === 200) {
      historyCompleted.value = true;
      thread.value.messages = [];

      return true;
    }
    return false;
  };

  const loadOlderMessages = async () => {
    const first = [...(thread.value?.messages || [])].pop();

    const query = qs.stringify({
      cursor: first.id,
      assistant,
      scope,
    });
    const response = await http.post(`/thread?${query}`);

    handleNewMessages(response);
  };

  return {
    thread,
    addMessage,
    addMessageJson,
    loadOlderMessages,
    deleteChatHistory,
    historyCompleted,
    isResponding,
    isLoading,
    isChatUnavailable,
  };
};
