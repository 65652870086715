import { Browser } from '@capacitor/browser';

const InAppBrowser = Vue => {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$inAppBrowser = async ({ url }) => {
    await Browser.open({
      presentationStyle: 'popover',
      url,
    });
  };
};

export default InAppBrowser;
