<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<script>
  import { JumButton, JumHeading, JumInputField, JumInputLabel } from '@blancofoodcoach/kompas';
  import { validationMixin } from '@/modules/shared/mixins/validation.mixin';
  import { setAccessoryBarVisible } from '@/modules/shared/mixins/setAccessoryBarVisible.mixin';
  import FcFormGroup from '@/modules/shared/components/form-group/FormGroup';
  import { defineComponent, ref } from 'vue';
  import { useRouter } from 'vue-router/composables';
  import REQUEST_PASSWORD_RESET_MUTATION from '@/modules/shared/auth/graphql/mutations/requestPasswordReset.mutation.graphql';
  import { useVueProxy } from '@/composables/useVueProxy';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';

  export default defineComponent({
    name: 'ForgotPasswordPage',
    components: {
      DomPurify,
      JumButton,
      JumHeading,
      JumInputField,
      JumInputLabel,
      FcFormGroup,
    },
    mixins: [setAccessoryBarVisible, validationMixin],
    layout: 'login',
    setup() {
      const { apollo } = useVueProxy();
      const router = useRouter();
      const form = ref(null);
      const authFormLoading = ref(false);
      const authForm = ref({
        email: '',
      });
      const formSubmit = async () => {
        const isCorrect = await form.value.validate();

        if (isCorrect) {
          authFormLoading.value = true;

          const { email } = authForm.value;
          await apollo
            .mutate({
              mutation: REQUEST_PASSWORD_RESET_MUTATION,
              variables: {
                email,
              },
            })
            .then(() => {
              router.push({ name: 'auth-reset-password-confirmation' });
            });
        }
      };

      return {
        authFormLoading,
        authForm,
        formSubmit,
        form,
      };
    },
  });
</script>

<template>
  <fc-page class="login-page">
    <template #top>
      <fc-nav-bar>
        {{ $t('auth.titles.resetPassword') }}
      </fc-nav-bar>
    </template>

    <fc-page-content>
      <validation-observer ref="form" v-slot="{ fields }">
        <form
          id="forgot-password-form"
          class="forgot-password-page__form"
          novalidate
          autocomplete="off"
          @submit.prevent="formSubmit"
        >
          <jum-heading h3 class="margin-bottom-m">
            <dom-purify :html="$t('auth.headings.forgotPassword')" />
          </jum-heading>

          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="auth_username_required|auth_username_email"
            slim
          >
            <fc-form-group>
              <jum-input-label for="email">{{ $t('shared.labels.email') }}</jum-input-label>

              <jum-input-field
                id="email"
                v-model="authForm.email"
                :valid="fieldIsValid(fields, 'email')"
                :invalid="fieldIsInvalid(fields, 'email')"
                :error-message="errors[0]"
                autocomplete="email"
                autofocus
                data-test-id="email"
                inputmode="email"
                name="email"
                type="text"
                class="input-field"
              />
            </fc-form-group>
          </validation-provider>
        </form>
      </validation-observer>

      <div class="note">
        <dom-purify :html="$t('auth.misc.forgotPasswordInfo')" />
      </div>
    </fc-page-content>

    <template #bottom>
      <fc-action-bar>
        <jum-button
          data-test-id="buttonResetPassword"
          form="forgot-password-form"
          block
          type="submit"
          :in-progress="authFormLoading"
          :disabled="authFormLoading"
        >
          {{ $t('auth.buttons.resetPassword') }}
        </jum-button>
      </fc-action-bar>
    </template>
  </fc-page>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .tiny {
    font-size: 12px;
    line-height: 1.5;
  }

  .input-field {
    width: 100%;
  }

  .error-message {
    color: $color-prominent-darken-20;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin-left: $spacing-m;
    margin-top: 2px;
  }

  .note {
    margin-left: $spacing-m;
    margin-top: $spacing-s;
  }
</style>
