import { Popover } from './types/popover';
import { Notification } from './types/notification';
import { Modal } from './types/modal';
import { TipMenu } from './types/tipmenu';
import { events } from './events';
import RenderlessItems from './components/RenderlessItems';

const determineSettings = params => {
  let settings;

  switch (typeof params) {
    case 'string': {
      settings = { title: params };
      break;
    }
    case 'object': {
      settings = params;
      break;
    }
    default: {
      throw new TypeError(
        `Expected dialog params to be of type Object or String got '${typeof params}' instead`
      );
    }
  }

  return settings;
};

export const popovery = params => {
  const settings = determineSettings(params);
  const popover = new Popover(settings);
  events.$emit('create', popover);

  return popover;
};

export const modaly = params => {
  const settings = determineSettings(params);
  const modal = new Modal(settings);
  events.$emit('create', modal);

  return modal;
};

export const tipmenu = params => {
  const settings = determineSettings(params);
  const tipMenu = new TipMenu(settings);
  events.$emit('create', tipMenu);

  return tipMenu;
};

export const notificationy = params => {
  events.$emit('clear');
  const settings = determineSettings(params);
  const notification = new Notification(settings);
  events.$emit('create', notification);

  return notification;
};

export const Dialogs = Vue => {
  Vue.component('RenderlessItems', RenderlessItems);

  popovery.$clear = () => events.$emit('clear');
  notificationy.$clear = () => events.$emit('clear');
  modaly.$clear = () => events.$emit('clear');

  /* eslint-disable no-param-reassign */
  Vue.prototype.$popovery = popovery;
  Vue.prototype.$notificationy = notificationy;
  Vue.prototype.$modaly = modaly;
  Vue.prototype.$tipmenu = tipmenu;
  /* eslint-enable no-param-reassign */
};
