<script>
  import DomPurify from '@/components/dom-purify/DomPurify.vue';
  import { JumIcon } from '@blancofoodcoach/kompas';

  export default {
    components: { DomPurify, JumIcon },
    props: {
      groupedMessages: {
        type: Array,
        default: () => [],
      },
      hideDates: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<template>
  <div class="chat">
    <div v-for="[date, messages] in groupedMessages" :key="`date-${date}`" class="date-group">
      <div v-if="!hideDates" class="date-balloon">
        {{ date }}
      </div>

      <template v-for="message in messages">
        <div
          :key="`message-${message.id}`"
          class="chat__balloon"
          :class="{ 'chat__balloon--other': message.sender === 'assistant' }"
        >
          <dom-purify :html="message.text" />
          <jum-icon v-if="message.completed" icon="fc-check" />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .chat {
    display: flex;
    flex-direction: column;
    gap: 16px;

    :deep(*) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.3rem;
        margin: 5px 0;
      }

      table {
        border-collapse: collapse;
        margin: 16px 0;
        width: 100%;
      }

      th,
      td {
        border: 1px solid #ddd;
        padding: 12px 16px;
        text-align: left;
      }

      th {
        background-color: #f4f4f4;
        font-weight: 700;
      }

      tr:nth-child(odd) {
        background-color: #f4f3f9;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      p {
        margin-bottom: 0;
      }

      ol {
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style: none;
        padding-left: 30px;

        > li {
          position: relative;

          ul {
            padding: 1rem;
          }

          &::before {
            align-items: center;
            background-color: $color-black;
            border-radius: 50%;
            color: #fff;
            content: counter(list-item);
            display: inline-flex;
            font-size: 0.9rem;
            font-weight: 600;
            height: 20px;
            justify-content: center;
            left: -30px;
            margin-right: 10px;
            position: absolute;
            top: 2px;
            width: 20px;
          }
        }
      }
    }

    &__balloon {
      background-color: #f1f1f1;
      border-radius: 8px 8px 0;
      font-size: 0.9rem;
      gap: 10px;
      margin: 0 0 0 auto;
      max-width: 70vw;
      padding: 8px 16px;

      &--other {
        background-color: #f4f3f9;
        border-radius: 8px 8px 8px 0;
        margin: 0 auto 0 0;
      }

      &--typing {
        padding: 0;
      }
    }
  }

  .date-balloon {
    background-color: #e3e3e3;
    border-radius: 4px;
    color: #5e5e5e;
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 0.9rem;
    margin: 0 auto;
    padding: 6px;
  }

  .date-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
</style>
