<script>
  import { JumHeading } from '@blancofoodcoach/kompas';
  import NicePopover from '@/modules/shared/components/nice-popovers/NicePopover';
  import FcLink from '@/modules/shared/components/link/Link';
  import { defineComponent } from 'vue';
  import FcPrivacyStatementSections from './PrivacyStatementSections';

  export default defineComponent({
    name: 'FcPrivacyStatementPopover',
    // eslint-disable-next-line vue/no-unused-components
    components: { FcPrivacyStatementSections, NicePopover, JumHeading, FcLink },
    props: {
      popover: {
        type: Object,
        default: () => {},
      },
    },
    data: vm => ({
      statements: vm.$i18n.messages[vm.$i18n.locale].shared.privacyStatement.content,
    }),
    created() {
      // eslint-disable-next-line vue/no-mutating-props
      this.popover.title = `${this.$t('shared.privacyStatement.title')} - ${this.$t(
        'shared.privacyStatement.subtitle'
      )}`;
    },
  });
</script>

<template>
  <nice-popover :popover="popover">
    <template #body>
      <fc-privacy-statement-sections
        v-for="(section, key) in statements"
        :key="key"
        :section="section"
        :i18n-path-base="`shared.privacyStatement.content[${key}]`"
      />
    </template>
  </nice-popover>
</template>
