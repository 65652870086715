<script>
  import { JumButton } from '@blancofoodcoach/kompas';
  import AcceptProTermsConditions from '@/modules/shared/components/acceptProTermsConditions/AcceptProTermsConditions';
  import NicePopover from '@/modules/shared/components/nice-popovers/NicePopover';
  import ACCEPT_INVITATION_MUTATION from '@/modules/shared/graphql/mutations/acceptProInvitation.mutation.graphql';
  import DECLINE_INVITATION_MUTATION from '@/modules/shared/graphql/mutations/declineProInvitation.mutation.graphql';
  import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';
  import { PRO_STATUS } from '@/modules/shared/constants/pro.const';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ProTermsConditionsPopover',
    components: {
      AcceptProTermsConditions,
      JumButton,
      NicePopover,
    },
    props: {
      popover: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        choice: undefined,
      };
    },
    methods: {
      async respondInvitation(accepted) {
        await this.$apollo.mutate({
          mutation: accepted ? ACCEPT_INVITATION_MUTATION : DECLINE_INVITATION_MUTATION,
          update: cache => {
            const data = cache.readQuery({ query: GET_USER_PROFILE_QUERY });
            data.getUserProfile.user.proStatus = accepted
              ? PRO_STATUS.ACCEPTED
              : PRO_STATUS.DECLINED;
            cache.writeQuery({ query: GET_USER_PROFILE_QUERY, data });
          },
        });
      },
      async submit() {
        await this.respondInvitation(this.choice);
        this.popover.close();
      },
    },
  });
</script>

<template>
  <nice-popover :popover="popover">
    <template #body>
      <div class="inner-container">
        <accept-pro-terms-conditions v-model="choice" />
      </div>
    </template>

    <template #footer>
      <jum-button primary @click="submit">{{ $t('pro.savePreference') }}</jum-button>
    </template>
  </nice-popover>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .animation {
    margin: auto;
    padding: 0 $spacing-l $spacing-l;
  }

  .inner-container {
    display: block;
    height: calc(100vh - 175px);
    overflow: auto;
    padding-bottom: $spacing-m;
  }
</style>
