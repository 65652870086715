<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcBadge',
    props: {
      primary: {
        default: false,
        type: Boolean,
      },
      gray: {
        default: false,
        type: Boolean,
      },
      orange: {
        default: false,
        type: Boolean,
      },
      cyan: {
        default: false,
        type: Boolean,
      },
      success: {
        default: false,
        type: Boolean,
      },
      blue: {
        default: false,
        type: Boolean,
      },
      inverted: {
        default: false,
        type: Boolean,
      },
    },
  });
</script>

<template>
  <div
    :class="{
      'fc-badge--primary': primary,
      'fc-badge--gray': gray,
      'fc-badge--orange': orange,
      'fc-badge--success': success,
      'fc-badge--blue': blue,
      'fc-badge--cyan': cyan,
      inverted,
    }"
    class="fc-badge"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .fc-badge {
    align-items: center;
    background-color: $color-gray-darken-40;
    border: solid 1px $color-gray-darken-40;
    border-radius: $spacing-xs;
    color: $color-white;
    display: inline-block;
    font-size: $font-size-small;
    font-weight: bold;
    line-height: $line-height-tiny;
    padding: 1px #{$spacing-s - 1px};
    text-align: center;

    &--primary {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-gray-darken-80;

      &.inverted {
        background-color: $color-white;
        color: $color-primary;
      }
    }

    &--gray {
      background-color: $color-gray-lighten-60;
      border-color: $color-gray-lighten-60;
      color: $color-black;

      &.inverted {
        background-color: $color-white;
        color: $color-gray-lighten-60;
      }
    }

    &--blue {
      background-color: #11aeed;
      border-color: #11aeed;
      color: $color-white;

      &.inverted {
        background-color: $color-white;
        color: #11aeed;
      }
    }

    &--cyan {
      background-color: #05d7b1;
      border-color: #05d7b1;
      color: $color-white;

      &.inverted {
        background-color: $color-white;
        color: #05d7b1;
      }
    }

    &--success {
      background-color: $color-success;
      border-color: $color-success;
      color: $color-white;

      &.inverted {
        background-color: $color-white;
        color: $color-success;
      }
    }

    &--orange {
      background-color: $color-orange;
      border-color: $color-orange;
      color: $color-white;

      &.inverted {
        background-color: $color-white;
        color: $color-orange;
      }
    }
  }
</style>
