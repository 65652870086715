<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<script>
  import {
    JumButton,
    JumHeading,
    JumInputField,
    JumInputLabel,
    JumNotification,
    JumSelect,
  } from '@blancofoodcoach/kompas';
  import { validationMixin } from '@/modules/shared/mixins/validation.mixin';
  import { setAccessoryBarVisible } from '@/modules/shared/mixins/setAccessoryBarVisible.mixin';
  import { register } from '@/modules/shared/services/auth/auth.service';
  import FcFormGroup from '@/modules/shared/components/form-group/FormGroup.vue';
  import { DEFAULT_LANGUAGE, LANGUAGES_OPTIONS } from '@/modules/shared/constants/profile.const';
  import { useI18n } from '@/modules/core/composables/useI18n';
  import { computed, defineComponent } from 'vue';
  import { setLocale } from '@/modules/i18n/util/setLocale';
  import { useRouter } from 'vue-router/composables';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import { watchOnce } from '@vueuse/core';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';

  const USER_ALREADY_EXISTS_ERROR_CODE = 'USR010';

  export default defineComponent({
    name: 'LoginPage',
    components: {
      DomPurify,
      JumButton,
      JumHeading,
      JumInputField,
      JumInputLabel,
      FcFormGroup,
      JumNotification,
      JumSelect,
    },
    mixins: [setAccessoryBarVisible, validationMixin],
    layout: 'login',
    setup() {
      const router = useRouter();
      const { t } = useI18n();
      const { user } = useProfile();
      watchOnce(user, value => {
        if (value) {
          router.push({ name: 'home' });
        }
      });

      const languageOptions = computed(() =>
        LANGUAGES_OPTIONS.map(({ value, label }) => ({
          value,
          label: t(label),
        }))
      );

      return {
        languageOptions,
      };
    },
    data: () => ({
      errorMessage: '',
      authFormLoading: false,
      authForm: {
        preferredLanguage: DEFAULT_LANGUAGE,
        email: '',
        password: '',
        givenName: '',
        middleName: '',
        familyName: '',
      },
      showPassword: false,
    }),
    computed: {
      showPasswordLabel: vm =>
        vm.showPassword ? vm.$t('core.buttons.password.hide') : vm.$t('core.buttons.password.show'),
    },
    watch: {
      'authForm.preferredLanguage': function (locale) {
        setLocale(this.$i18n, locale);
      },
    },
    methods: {
      async registerFormSubmit() {
        const isCorrect = await this.$refs.authForm.validate();

        if (!isCorrect) {
          this.$el.querySelectorAll('input').forEach(input => {
            input.focus();
            input.blur();
          });
        }

        if (isCorrect) {
          const { preferredLanguage, username, password, givenName, middleName, familyName } =
            this.authForm;

          this.authFormLoading = true;

          try {
            const { data } = await register(
              username,
              password,
              givenName,
              middleName,
              familyName,
              preferredLanguage
            );

            if (data?.registerUser?.user?.customerId) {
              this.$notificationy({
                message: this.$t('auth.notifications.accountCreated'),
                icon: 'jum-check',
                iconColor: '#0fc647',
              });

              await this.$router.replace({ name: 'auth-login' });
            } else {
              this.errorMessage = this.$t('shared.networkErrors.default');
            }
          } catch (error) {
            const graphQLError = error?.graphQLErrors[0];

            if (graphQLError?.code === USER_ALREADY_EXISTS_ERROR_CODE) {
              this.errorMessage = this.$t('shared.networkErrors.accountExists');
            } else {
              this.errorMessage = this.$t('shared.networkErrors.default');
            }
          } finally {
            this.authFormLoading = false;
          }
        }
      },
    },
  });
</script>

<template>
  <fc-page class="login-page">
    <template #top>
      <fc-nav-bar :back-button="false" />
    </template>

    <fc-page-content>
      <validation-observer ref="authForm" v-slot="{ fields }">
        <form
          id="register-form"
          class="register-page__form"
          novalidate
          autocomplete="off"
          @submit.prevent="registerFormSubmit"
        >
          <jum-heading h3 class="margin-bottom-m">
            <dom-purify tag="strong" :html="$t('auth.headings.register.title')" />
          </jum-heading>

          <h4 class="subtitle">
            {{ $t('auth.headings.register.subtitle') }}
          </h4>

          <jum-notification
            v-if="errorMessage"
            type="prominent"
            data-test-id="register-network-error"
            class="margin-bottom-m"
          >
            <dom-purify tag="p" :html="errorMessage" />
          </jum-notification>

          <fc-form-group>
            <jum-input-label for="preferredLanguage"
              >{{ $t('shared.labels.language') }}
            </jum-input-label>

            <jum-select
              id="preferredLanguage"
              v-model="authForm.preferredLanguage"
              :options="languageOptions"
              alignment="left"
            />
          </fc-form-group>

          <validation-provider
            v-slot="{ errors }"
            name="givenName"
            rules="auth_given_name_required"
            slim
          >
            <fc-form-group>
              <jum-input-label for="given-name"
                >{{ $t('shared.labels.givenName') }}
              </jum-input-label>

              <jum-input-field
                id="givenName"
                v-model="authForm.givenName"
                :valid="fieldIsValid(fields, 'givenName')"
                :invalid="fieldIsInvalid(fields, 'givenName')"
                :error-message="errors[0]"
                autocomplete="givenName"
                autofocus
                data-test-id="givenName"
                inputmode="text"
                name="given-name"
                type="text"
                class="input-field"
                :placeholder="$t('auth.placeholders.givenName')"
              />
            </fc-form-group>
          </validation-provider>

          <validation-provider v-slot="{ errors }" name="middleName" slim>
            <fc-form-group>
              <jum-input-label for="middle-name"
                >{{ $t('shared.labels.middleName') }}
                {{ $t('shared.labels.optional') }}
              </jum-input-label>

              <jum-input-field
                id="middleName"
                v-model="authForm.middleName"
                :valid="fieldIsValid(fields, 'middleName')"
                :invalid="fieldIsInvalid(fields, 'middleName')"
                :error-message="errors[0]"
                autocomplete="middleName"
                autofocus
                data-test-id="middleName"
                inputmode="text"
                name="middle-name"
                type="text"
                class="input-field"
                :placeholder="$t('auth.placeholders.middleName')"
              />
            </fc-form-group>
          </validation-provider>

          <validation-provider v-slot="{ errors }" name="familyName" slim>
            <fc-form-group>
              <jum-input-label for="middle-name"
                >{{ $t('shared.labels.familyName') }}
                {{ $t('shared.labels.optional') }}
              </jum-input-label>

              <jum-input-field
                id="familyName"
                v-model="authForm.familyName"
                :valid="fieldIsValid(fields, 'familyName')"
                :invalid="fieldIsInvalid(fields, 'familyName')"
                :error-message="errors[0]"
                autocomplete="familyName"
                autofocus
                data-test-id="familyName"
                inputmode="text"
                name="last-name"
                type="text"
                class="input-field"
                :placeholder="$t('auth.placeholders.familyName')"
              />
            </fc-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="username"
            rules="auth_username_required|auth_username_email"
            slim
          >
            <fc-form-group>
              <jum-input-label for="email">{{ $t('shared.labels.email') }}</jum-input-label>

              <jum-input-field
                id="email"
                v-model="authForm.username"
                :valid="fieldIsValid(fields, 'username')"
                :invalid="fieldIsInvalid(fields, 'username')"
                :error-message="errors[0]"
                autocomplete="username"
                autofocus
                data-test-id="username"
                inputmode="email"
                name="username"
                type="text"
                class="input-field"
                :placeholder="$t('auth.placeholders.email')"
              />
            </fc-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="password"
            rules="auth_password_required|auth_password_special_characters|general_min_length:8|auth_password_one_uppercase|auth_password_one_lowercase|auth_password_one_number"
            :bails="false"
          >
            <fc-form-group>
              <jum-input-label for="password">{{ $t('shared.labels.password') }}</jum-input-label>

              <jum-input-field
                id="password"
                v-model="authForm.password"
                :valid="fieldIsValid(fields, 'password')"
                :invalid="fieldIsInvalid(fields, 'password')"
                :error-message="errors[0]"
                autocomplete="password"
                data-test-id="password"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                class="input-field"
                :placeholder="$t('auth.placeholders.password')"
              >
                <template #pre>
                  <button
                    v-if="authForm.password.length > 0"
                    type="button"
                    @click="showPassword = !showPassword"
                  >
                    {{ showPasswordLabel }}
                  </button>
                </template>
              </jum-input-field>

              <dom-purify class="disclaimer" :html="$t('core.inputErrors.invalid.rules')" />
            </fc-form-group>
          </validation-provider>
        </form>
      </validation-observer>
    </fc-page-content>

    <template #bottom>
      <fc-action-bar>
        <jum-button
          data-test-id="buttonLogIn"
          form="register-form"
          block
          type="submit"
          :in-progress="authFormLoading"
          :disabled="authFormLoading"
        >
          {{ $t('auth.buttons.createAccount') }}
        </jum-button>

        <router-link v-slot="{ navigate }" :to="{ name: 'auth-login' }" custom>
          <jum-button
            block
            class="back-to-login-button"
            data-test-id="backToLoginButton"
            tertiary
            @click="navigate"
          >
            <dom-purify :html="$t('auth.links.backToLogin')" />
          </jum-button>
        </router-link>
      </fc-action-bar>
    </template>
  </fc-page>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  :deep(.jum-select) {
    margin-top: $spacing-xs;
    width: 100%;
  }

  .back-to-login-button {
    font-weight: 400 !important;

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }

  .tiny {
    font-size: 12px;
    line-height: 1.5;
  }

  .input-field {
    width: 100%;
  }

  .error-message {
    color: $color-prominent-darken-20;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin-left: $spacing-m;
    margin-top: $spacing-xs;
  }

  h3.jum-heading {
    margin-bottom: 0 !important;
  }

  .subtitle {
    margin-bottom: 16px !important;
  }

  .disclaimer {
    display: block;
    margin-top: 2rem;
  }

  :deep(.pre) {
    height: 1.5rem;
    position: absolute;
    right: 32px;

    button {
      all: initial;
      background-color: $color-white;
      font-family: $font-family-outfit;
      font-size: 1rem;
      padding: 0 1rem;
    }
  }
</style>
