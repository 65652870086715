import { render, staticRenderFns } from "./ForgotPasswordPage.vue?vue&type=template&id=ab0af936&scoped=true"
import script from "./ForgotPasswordPage.vue?vue&type=script&lang=js"
export * from "./ForgotPasswordPage.vue?vue&type=script&lang=js"
import style0 from "./ForgotPasswordPage.vue?vue&type=style&index=0&id=ab0af936&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab0af936",
  null
  
)

export default component.exports