<script>
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    name: 'CardSkeletonLoader',
    props: {
      height: {
        type: Number,
        default: 200,
      },
    },
    setup(props) {
      const heightInPx = computed(() => `${props.height}px`);

      return {
        heightInPx,
      };
    },
  });
</script>

<template>
  <div class="skeleton-loader"></div>
</template>

<style scoped lang="scss">
  .skeleton-loader {
    animation: 1.5s shine linear infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    border-radius: 8px;
    height: v-bind(heightInPx);
    width: 100%;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
</style>
