import VueApollo from 'vue-apollo';
import apolloProvider from '@/modules/shared/graphql/apollo.config';

export const apolloModule = () => ({
  install(Vue) {
    Vue.use(VueApollo);

    this.apolloProvider = apolloProvider;
  },
});
