<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<script>
  import { JumIcon, JumInputField } from '@blancofoodcoach/kompas';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcSearchInput',
    components: { JumIcon, JumInputField },
    props: {
      value: {
        default: '',
        required: false,
        type: String,
      },
      placeholder: {
        default: '',
        type: String,
      },
      autofocus: {
        default: false,
        type: Boolean,
      },
      noBorder: {
        default: false,
        type: Boolean,
      },
    },
    data: vm => ({
      query: vm.value,
    }),
    watch: {
      value(newValue) {
        this.query = newValue;
      },
    },
    methods: {
      cleanInput() {
        this.query = '';
        this.$emit('input', this.query);
        this.focus();
      },
      focus() {
        this.$refs.nativeInput.focusInput();
      },
    },
  });
</script>

<template>
  <div class="jum-search-input">
    <jum-input-field
      id="query"
      ref="nativeInput"
      v-model="query"
      data-text-id="query"
      type="search"
      name="query"
      class="jum-search-input__input"
      :class="{ 'jum-search-input__input--no-border': noBorder }"
      :placeholder="placeholder"
      :autofocus="autofocus"
      @input="event => $emit('input', event.target.value)"
    >
      <template #pre><jum-icon v-if="!noBorder" icon="jum-search" /></template>
    </jum-input-field>

    <button v-show="query" type="button" class="jum-search-input__clean" @click="cleanInput">
      <jum-icon icon="jum-cross" class="jum-search-input__clean-icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .jum-search-input {
    position: relative;

    &__input {
      width: 100%;

      &[type='search'] {
        // stylelint-disable-next-line property-no-vendor-prefix
        -webkit-appearance: none;
      }

      &[type='search']::-webkit-search-cancel-button,
      &[type='search']::-webkit-search-decoration {
        // stylelint-disable-next-line property-no-vendor-prefix
        -webkit-appearance: none;
      }
    }

    &__clean {
      align-items: center;
      background-color: transparent;
      border: 0;
      display: flex;
      height: 42px;
      justify-content: center;
      padding: 0 10px 0 0;
      position: absolute;
      right: 0;
      top: 2px;
      width: 40px;
    }

    &__clean-icon {
      background-color: $color-gray;
      border-radius: 100%;
      color: $color-white;
      height: 18px;
      padding: 4px;
      width: 18px;
    }
  }
</style>

<style lang="scss">
  @import '~@/styles/global.scss';

  .jum-search-input {
    position: relative;

    &__input {
      width: 100%;

      &--no-border {
        .container {
          border: 0 !important;
          outline: 0 !important;
        }

        .container:focus-within {
          border: 0 !important;
        }
      }
    }
  }
</style>
