<script>
  import { defineComponent } from 'vue';
  import { JumButton, JumHeading, JumIcon } from '@blancofoodcoach/kompas';

  export default defineComponent({
    name: 'MedalPopup',
    components: {
      JumHeading,
      JumButton,
      JumIcon,
    },
    props: {
      medal: {
        type: Object,
        required: true,
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['proceed', 'cancel'],
    setup(props, { emit }) {
      const handleClick = () => {
        emit('proceed');
      };

      const close = () => {
        emit('cancel');
      };

      return {
        handleClick,
        close,
      };
    },
  });
</script>

<template>
  <div class="overlay" :class="{ 'is-open': isOpen }" @click="close">
    <div class="medal-popup" @click.stop>
      <div class="close">
        <jum-icon icon="jum-cross" :size="15" @click.native="close" />
      </div>

      <jum-heading bold h4> Challenge completed!</jum-heading>

      <p>Boooom 🚀 You’ve completed the ‘{{ medal.challenge.title }}’ challenge!</p>

      <div class="image-wrapper shine">
        <img :src="medal.imageUrl" alt="Rewarded medal" />
      </div>

      <jum-button secondary block @click="handleClick"> Add to my medals</jum-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .shine {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    &::before {
      background: linear-gradient(to right, fade_out(#fff, 1) 0%, fade_out(#fff, 0.7) 100%);
      content: '';
      display: block;
      height: 100%;
      left: -75%;
      position: absolute;
      top: 0;
      transform: skewX(-25deg);
      width: 50%;
      z-index: 2;
    }

    &:hover,
    &:focus {
      &::before {
        animation: shine 0.85s;
      }
    }
  }

  .overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 9999;

    .medal-popup {
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 16px;
      max-width: 300px;
      overflow: hidden;
      padding: 32px 24px 24px;
      position: relative;
      width: 100%;

      .close {
        cursor: pointer;
        margin-left: auto;
        position: absolute;
        right: 15px;
        top: 15px;
      }

      .jum-heading {
        margin: 0 0 -10px;
      }

      p {
        margin: 0;
      }

      :deep(.jum-button) {
        margin-top: auto;
      }

      img {
        margin: 5px 0;
        min-height: 100px;
        transition: all 1s ease-in-out;
      }
    }

    &.is-open {
      opacity: 0.99;
      visibility: visible;

      img {
        animation: spin 2s ease-in-out;
      }

      .shine {
        &::before {
          animation: 4s shine 1.5s;
        }
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate3d(0, 1, 0, 0deg);
    }

    100% {
      transform: rotate3d(0, 1, 0, 1080deg);
    }
  }

  @keyframes shine {
    0% {
      left: -75%;
    }

    100% {
      left: 125%;
    }
  }
</style>
