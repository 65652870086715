<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcNote',
  });
</script>

<template>
  <div class="note">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  .note {
    background-color: #fdf8e8;
    font-size: 14px;
    font-weight: 300;
    padding: 16px;
  }
</style>
