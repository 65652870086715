export default {
  energyCalculationSaved: 'Energy calculation is saved',
  givenNameSaved: 'First name is saved',
  middleNameSaved: 'Middle name is saved',
  familyNameSaved: 'Family name is saved',
  preferredLanguageSaved: 'Language is saved',
  ProductDatabaseSaved: 'Product database is saved',
  privacyStatementSaved: 'Your privacy settings are saved',
  privacyStatementFailed: 'Saving your privacy settings has failed',
  fieldSaved: '{field} is saved',
  eventSaved: 'Your event is saved',
  eventRemoved: 'Your event is removed',
  devices: {
    garmin: {
      connectSuccess: 'Successfully connected to Garmin Connect',
      connectFailed: 'Failed to connect to Garmin Connect',
      disconnectSuccess: 'Successfully disconnected from Garmin Connect',
      disconnectFailed: 'Failed to disconnect from Garmin Connect',
    },
    wahoo: {
      connectSuccess: 'Successfully connected to Wahoo',
      connectFailed: 'Failed to connect to Wahoo',
      disconnectSuccess: 'Successfully disconnected from Wahoo',
      disconnectFailed: 'Failed to disconnect from Wahoo',
    },
    strava: {
      connectSuccess: 'Successfully connected to Strava',
      connectFailed: 'Failed to connect to Strava',
      disconnectSuccess: 'Successfully disconnected from Strava',
      disconnectFailed: 'Failed to disconnect from Strava',
    },
    trainingpeaks: {
      connectSuccess: 'Successfully connected to TrainingPeaks',
      connectFailed: 'Failed to connect to TrainingPeaks',
      disconnectSuccess: 'Successfully disconnected from TrainingPeaks',
      disconnectFailed: 'Failed to disconnect from TrainingPeaks',
    },
    join: {
      dialog: 'Use the JOIN app to connect with FoodCoach',
      approveFailed: 'Something went wrong while approving the connection, please try again later',
      disconnectSuccess: 'Successfully disconnected from JOIN',
      disconnectFailed: 'Failed to disconnect from JOIN',
      confirmText: 'Close',
    },
    intervals: {
      connectSuccess: 'Successfully connected to Intervals.icu',
      connectFailed: 'Failed to connect to Intervals.icu',
      disconnectSuccess: 'Successfully disconnected from Intervals.icu',
      disconnectFailed: 'Failed to disconnect from Intervals.icu',
    },
  },
};
