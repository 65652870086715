import InAppBrowser from '@/modules/core/plugins/inAppBrowser';
import { Dialogs } from '@/modules/core/plugins/renderlessBuss';
import GlobalComponents from '@/modules/core/utils/global-components.util';
import { getUserProfile } from '@/modules/shared/services/profile/profile.service';
import { hasValidSubscription } from '@/modules/core/utils/user';
import messages from './messages/nl/core.nl';
import messagesEn from './messages/en/core.en';
import { appOpensMixin } from './mixins/appOpens.mixin';
import { registerRoutes } from './routes';

/* eslint-disable import/no-mutable-exports */
export let mixins = {};
/* eslint-enable import/no-mutable-exports */

// Register module
export const coreModule = (sharedModule, { router }, i18nModule) => ({
  mixins: { appOpensMixin, ...sharedModule.mixins },
  install(Vue) {
    Vue.use(InAppBrowser, router);
    Vue.use(Dialogs);
    Vue.use(GlobalComponents);

    mixins = this.mixins;

    i18nModule.setTranslations({ core: { ...messages } }, 'nl');
    i18nModule.setTranslations({ core: { ...messagesEn } }, 'en');
    registerRoutes(router);

    router.beforeEach(async (to, from, next) => {
      if (to.meta.noPayWall) {
        return next();
      }

      try {
        const user = await getUserProfile();

        if (hasValidSubscription(user)) {
          return next();
        }

        return next({ name: 'premium' });
      } catch {
        return next({
          name: 'auth-login',
          query: {
            returnUrl: to.fullPath,
          },
        });
      }
    });
  },
});
