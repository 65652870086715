export default {
  givenName: 'First name',
  middleName: 'Middle name',
  familyName: 'Family name',
  emails: 'Emails from the Athlete’s FoodCoach',
  preferredLanguage: 'Select your preferred language',
  performanceGoal: 'Performance goal',
  performanceGoalPlaceholder: 'E.g. your event',
  date: 'Date',
  energyCalculation: {
    hb: {
      title: 'Harris & Benedict',
      description: 'Standard used for amateur athletes',
    },
    th: {
      title: 'Ten Haaf',
      description: 'Used for pro and ultra fanatic athletes with relatively higher fat free mass',
    },
  },
  nutritionView: {
    coach: {
      title: 'Coach tailored',
      description: 'Your coach has set the view that’s most relevant for you',
    },
    core: {
      title: 'Core performance',
      description: 'Show only protein and carb bars to focus on core performance',
    },
    advanced: {
      title: 'Advanced performance',
      description:
        'Show calories, carbs, proteins, and fats for complete nutrition tracking and advanced nutrition strategies',
    },
  },
  weightGoal: {
    custom: 'Coach tailored target',
    weeklyTarget: 'Weekly target',
    dailyCalorieDeficit: 'Daily calorie deficit',
    dailyCalorieSurplus: 'Daily calorie surplus',
    maintain: {
      title: 'Maintain weight',
      description: 'Keep your current weight to support your health and performance.',
    },
    lose: {
      title: 'Smart weight loss',
      description: 'Lose weight gradually, while preserving muscle mass and performance.',
      targets: {
        halveKg: '-0.5 kg',
        quarterKg: '-0.25 kg',
      },
    },
    gain: {
      title: 'Smart weight gain',
      description: 'Gain weight gradually, while promoting muscle mass and minimizing fat gain.',
      targets: {
        halveKg: '+0.5 kg',
        quarterKg: '+0.25 kg',
      },
    },
  },
  productDatabaseOptions: {
    default: 'Default FoodCoach',
    off: 'Global Community-Driven Database',
    nl: 'Netherlands',
    be: 'Belgium (Dutch)',
    gb: 'United Kingdom',
    us: 'United States',
  },
  whichGender: '<strong>Which gender</strong> should we use to calculate your energy needs?',
};
