export default {
  add: 'Training toevoegen',
  blogs: 'Lees al onze _blogs',
  boostMeal: 'Maaltijd tunen',
  calculateEnergyNeed: 'Je energiebehoefte berekenen',
  deleteRecipe: 'Eigen recept verwijderen',
  edit: 'Wijzigen',
  editInfo: 'Gegevens bewerken',
  addTraining: 'Voeg mijn training toe',
  editTraining: 'Pas mijn training aan',
  next: 'Volgende',
  removeTraining: 'Training verwijderen',
  save: 'Opslaan',
  saveAsRecipe: 'Opslaan als recept',
  saveFtp: 'Door naar mijn training',
  select: 'Selecteren',
  planMeals: 'Plan mijn maaltijden in',
  updateActuals: 'Actuals aanpassen',
  pairActivity: 'Koppel aan geplande training',
  deleteActivity: 'Verwijder activiteit',
  unpairActivity: 'Ontkoppel van geplande training',
};
