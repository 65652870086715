<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<script>
  import { defineComponent, ref } from 'vue';
  import SearchProducts from '@/modules/planner/components/search-products/SearchProducts.vue';
  import FcSearchInput from '@/modules/shared/components/search-input/SearchInput.vue';
  import { useRoute, useRouter } from 'vue-router/composables';

  export default defineComponent({
    components: { FcSearchInput, SearchProducts },
    setup() {
      const route = useRoute();
      const { id, moment, date } = route.params;
      const router = useRouter();
      const searchQuery = ref(route.query.search || '');
      const navigateToDetails = async ingredientId => {
        if (searchQuery.value !== route.query.search) {
          await router.replace({
            query: {
              ...route.query,
              search: searchQuery.value,
            },
          });
        }

        await router.push({
          name: 'planner-user-recipe-add-product-details',
          params: {
            id,
            moment,
            date,
            ingredientId,
          },
        });
      };

      return {
        searchQuery,
        navigateToDetails,
      };
    },
  });
</script>

<template>
  <fc-page>
    <template #top>
      <fc-nav-bar>
        <fc-search-input
          v-model="searchQuery"
          data-test-id="search-input"
          :placeholder="$t('planner.titles.searchProducts')"
          autofocus
          no-border
        />
      </fc-nav-bar>
    </template>

    <search-products :search-query="searchQuery" @selected="navigateToDetails" />
  </fc-page>
</template>
