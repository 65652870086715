<script>
  import { JumHeading, JumInputLabel, JumSelect } from '@blancofoodcoach/kompas';
  import { TEAMSPORT, SPORTS } from '@/modules/shared/constants/profile.const';
  import FcFormGroup from '@/modules/shared/components/form-group/FormGroup';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SportDropdown',
    components: {
      JumInputLabel,
      JumSelect,
      FcFormGroup,
      JumHeading,
    },
    model: {
      prop: 'value',
      event: 'on-sport-change',
    },
    props: {
      value: {
        type: String,
        default: TEAMSPORT,
      },
    },
    data() {
      return {
        sportOptions: [
          {
            label: this.$t('onboardingSports.labels.choseYourSport'),
            value: TEAMSPORT,
            disabled: true,
          },
          {
            label: this.$t('onboardingSports.labels.sports.hockey'),
            value: SPORTS.HOCKEY,
          },
          {
            label: this.$t('onboardingSports.labels.sports.soccer'),
            value: SPORTS.SOCCER,
          },
        ],
      };
    },
  });
</script>

<template>
  <fc-form-group class="sport-dropdown">
    <jum-input-label for="sport-dropdown">
      <jum-heading h5 bold>{{ $t('onboardingSports.labels.whichTeamSport') }}</jum-heading>
    </jum-input-label>

    <jum-select
      id="sport-dropdown"
      :model-value="value"
      name="sport"
      data-test-id="dropdownSport"
      :options="sportOptions"
      @update:modelValue="value => $emit('on-sport-change', value)"
    />
  </fc-form-group>
</template>

<style lang="scss" scoped>
  .sport-dropdown {
    width: 100%;
  }
</style>
