const GroceriesPage = () => import('@/modules/groceries/pages/groceries/GroceriesPage');
const ProductsPage = () => import('@/modules/groceries/pages/products/ProductsPage');

export const registerRoutes = (router, guards) => {
  const routes = [
    {
      path: '/groceries',
      name: 'groceries',
      component: GroceriesPage,
      beforeEnter: guards,
      redirect: { name: 'groceries-products' },
      children: [
        {
          path: 'products',
          name: 'groceries-products',
          component: ProductsPage,
        },
      ],
    },
  ];

  routes.forEach(route => router.addRoute(route));
};
