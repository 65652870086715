import BaseType from './baseType';

export class Popover extends BaseType {
  constructor({
    title = '',
    message = '',
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    minimizable = false,
    component = null,
    onClose = null,
    onCancel = null,
    onConfirm = null,
    onMinimize = null,
    ...args
  }) {
    super(component, onClose);
    this.title = title;
    this.message = message;
    this.minimizable = minimizable;
    this.onCancel = onCancel;
    this.onConfirm = onConfirm;
    this.onMinimize = onMinimize;
    this.confirmText = confirmText;
    this.cancelText = cancelText;
    this.type = 'popover';

    Object.keys(args).forEach(key => {
      this[key] = args[key];
    });
  }

  cancel() {
    super.close();
    if (this.onCancel) this.onCancel(this);
  }

  confirm() {
    if (this.onConfirm) this.onConfirm(this);
  }

  minimize() {
    if (this.onMinimize) {
      this.onMinimize(this);
    }
    super.close();
  }
}
