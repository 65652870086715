<script setup>
  import NicePopover from '@/modules/shared/components/nice-popovers/NicePopover';
  import DoingDetail from '@/components/doing-detail/DoingDetail.vue';
  import { JumButton } from '@blancofoodcoach/kompas';
  import { useFocus } from '@/composables/useFocus';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import addPlaylistProgressMutation from '@/modules/shared/graphql/mutations/addPlaylistProgress.mutation.graphql';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { computed, onBeforeMount, ref } from 'vue';
  import getPlaylistProgressQuery from '@/modules/shared/graphql/queries/getPlaylistProgressQuery.query.graphql';
  import qs from 'qs';
  import http from '@/modules/shared/auth/services/http/strapiHttp.service';
  import { Doing } from '@/util/gamification/doing';
  import { useRouter } from 'vue-router/composables';
  import DoingNotFinishedPopup from '@/components/popup/doing-not-finished-popup/DoingNotFinishedPopup.vue';

  const router = useRouter();
  const { apollo } = useVueProxy();
  const { user } = useProfile();
  const props = defineProps({
    popover: {
      type: Object,
      default: () => {},
    },
  });
  const doing = ref(null);

  const { unsetFocus, context } = useFocus();
  const tag = computed(() => context.value?.tag);

  onBeforeMount(async () => {
    const progressResponse = await apollo.query({
      query: getPlaylistProgressQuery,
      variables: {
        playlistId: context.value.playlistUuid,
      },
      fetchPolicy: 'no-cache',
    });

    const query = qs.stringify({
      populate: 'deep,8',
      filters: {
        uuid: {
          $eq: context.value.doingUuid,
        },
      },
    });

    const response = await http.get(`actions?${query}`);
    const [resource] = response.data.data;
    doing.value = Doing.createFromStrapi(
      resource,
      progressResponse?.data?.playlist?.items ?? [],
      context.value.stepUuid
    );
  });

  const addProgress = async (id, itemId) => {
    await apollo.mutate({
      mutation: addPlaylistProgressMutation,
      variables: {
        playlistId: user.value.playlistId,
        itemId,
      },
    });
  };

  const showPopup = ref(false);
  const closePopup = () => {
    showPopup.value = false;
  };

  const markAsCompleted = async () => {
    if (!doing.value.isCompleted) {
      showPopup.value = true;
      return;
    }

    await addProgress(user.value.playlistId, context.value.stepUuid);

    if (context.value.callbackRoute) {
      router.push(context.value.callbackRoute, async () => {
        await unsetFocus();
        props.popover.close();
      });
    } else {
      await unsetFocus();
      props.popover.close();
    }
  };

  const pause = async () => {
    await unsetFocus();
    await props.popover.close();
  };

  const handleUpdate = async step => {
    await addProgress(context.value.playlistUuid, step.uuid);
  };
</script>

<template>
  <nice-popover :popover="popover">
    <template #body>
      <doing-detail
        v-if="doing"
        :doing="doing"
        :tag="tag"
        :is-active="true"
        @update="handleUpdate"
      />

      <portal to="overlay">
        <doing-not-finished-popup :is-open="showPopup" @close="closePopup" />
      </portal>
    </template>

    <template #footer>
      <fc-action-bar>
        <jum-button @click="markAsCompleted">
          {{ $t('doneLabel') }}
        </jum-button>

        <jum-button secondary @click="pause">
          {{ $t('pauseLabel') }}
        </jum-button>
      </fc-action-bar>
    </template>
  </nice-popover>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  :deep(.nice-popover__header) {
    align-items: center;
    justify-content: center;
    position: relative;

    .jum-heading {
      position: absolute;
    }
  }

  :deep(.nice-popover__title) {
    font-size: 1rem !important;
  }

  :deep(.fc-action-bar) {
    border-top: 0;
    padding: 0;
  }
</style>

<i18n>
{
  "en": {
    "pauseLabel": "Pause doing",
    "doneLabel": "I'm done"
  },
  "nl": {
    "pauseLabel": "Pauzeren",
    "doneLabel": "Ik ben klaar"
  }
}
</i18n>
