export default {
  buttons: {
    accountExists: 'Ik heb al een Blanco account',
    createAccount: 'Heb je nog geen account? <u>Meld je aan</u>',
    nextOnboardingCard: 'Volgende',
    skipOnboarding: 'Uitleg overslaan',
    password: {
      show: 'Tonen',
      hide: 'Verbergen',
    },
    cancel: 'Annuleren',
    select: 'Selecteren',
  },
  errors: {
    notSupported: 'Deze functie wordt niet ondersteund op jouw apparaat',
  },
  networkErrors: {
    trainingExistsOnDate: 'Je hebt al een training op deze datum',
  },
  serviceUnavailable: {
    heading: 'Onderhoud',
    title: 'Op dit moment zijn we bezig met een update',
    description: 'De app is voor korte tijd even niet te gebruiken. Probeer het later nog eens.',
  },
  inputErrors: {
    invalid: {
      rules:
        'Je wachtwoord <strong>moet</strong> minimaal 8 tekens lang zijn, een hoofdletter, een kleine letter, een cijfer en één speciaal teken bevatten (i.e. : !@#$%^&*)',
      date: 'Vul een geldige datum in',
      default: 'De ingevoerde waarde voldoet niet',
      greaterThan: 'Vul een getal in groter dan {greaterThanValue}',
      greaterThanOrEquals:
        'Voer een getal in dat groter is dan of gelijk is aan {greaterThanOrEqualsValue}',
      smallerThan: 'Voer een getal in dat kleiner is dan {smallerThanValue}',
      smallerThanOrEquals:
        'Voer een getal in dat kleiner is dan of gelijk is aan {smallerThanOrEqualsValue}',
      maxLength: 'Gebruik maximaal {maxLengthValue} tekens',
      maxPrecision: 'Gebruik maximaal {maxPrecision} cijfers achter de komma/punt',
      minLength: 'Gebruik minimaal {minLengthValue} tekens',
      number: 'Vul een getal in',
      decimal: 'Gebruik één cijfer achter de komma/punt',
      oneLowercase: 'Gebruik minimaal één kleine letter',
      oneUppercase: 'Gebruik minimaal één hoofdletter',
      oneNumber: 'Gebruik minimaal één cijfer',
      specialCharacters: 'Gebruik minimaal één speciaal teken i.e.: !@#$%^&*',
      userMealParticipants: 'Selecteer tenminste één persoon',
      username: 'Je hebt een ongeldig e-mailadres ingevuld',
      ftp: 'Vul minimaal 80, maximaal 500 watt in',
      dateOfBirth: "Je moet minimaal 16 jaar zijn om The Athlete's FoodCoach te gebruiken",
      activityHours: 'Vul minimaal 1, maximaal 99 uur in',
      height: 'Vul minimaal 130, maximaal 220 centimeter in',
      weight: 'Vul minimaal 40, maximaal 140 kg in',
      workDayHours: 'Vul maximaal 16 uur in',
      sleepingHours: 'Vul minimaal 1, maximaal 16 uur in',
      tempo:
        'Vul een tempo in tussen de 02:30 en 15:00. Zorg dat je vier cijfers gebruikt om de snelheid juist in te voeren.',
      wattage: 'Vul een intensiteit in tussen de 0 en 500',
      carbsPerHour: 'Vul minimaal 0 en maximaal 120 gram in',
      actualWattage: 'Vul maximaal 800 watt in',
      macroTotal: 'Het totaal van je macronutriënten mag niet hoger zijn dan 100',
    },
    required: {
      default: 'Dit veld is verplicht',
      password: 'Je hebt nog geen wachtwoord ingevuld',
      username: 'Je hebt nog geen e-mailadres ingevuld',
      givenName: 'Je hebt nog geen naam ingevuld',
      familyName: 'Je hebt nog geen achternaam ingevuld',
      gender: 'Geef aan of je een man of een vrouw bent',
      dateOfBirth: 'Vul je geboortedatum in',
      termsAndConditions: 'Je moet akkoord gaan om verder te gaan',
      sportsTermsAndConditions: 'Je toestemming is nodig om verder te gaan.',
      sportType: 'Selecteer je sport',
      activityLevelWorkDay: 'Selecteer het activiteitenniveau van je werkomstandigheden',
      activityLevel: 'Vul je activiteitenniveau buiten sport en werk in',
      duration: 'Selecteer de lengte van je training',
      wattage: 'Vul een intensiteit in tussen de 0 en 500',
      trainingMoment: 'Kies het moment van je activiteit',
      trainingType: 'Kies het type van je activiteit',
      eventName: 'Je hebt nog geen naam voor je prestatiedoel ingevuld',
      eventDate: 'Je hebt nog geen datum voor je prestatiedoel ingevuld',
    },
  },
  onboarding: {
    slide1: {
      title: 'Ben je een <strong>fanatieke sporter?</strong>',
      description:
        'Kies dan voor de sportmodule en krijg een persoonlijk voedingsplan om beter te presteren 💪',
    },
  },
  idfa: {
    title: 'Mogen we je activiteit volgen?',
    text: `Zo kunnen we je ook beter leren kennen via andere apps en websites om je nog beter van dienst te zijn.
    Wij willen hier eenmalig jouw toestemming voor vragen. Selecteer “Sta toe” op het volgende scherm voor de meest persoonlijke ervaring.`,
    confirmText: 'Geef je voorkeur',
  },
  applications: {
    title: 'Verbind je apps & apparaten',
    description:
      'FoodCoach zal automatisch fiets- of hardlooptrainingsgegevens importeren. Voor fietsers is vermogensmeterdata vereist voor een nauwkeurige import.',
    learnMore: 'Lees meer.',
  },
  premium: {
    pageTitle: 'FoodCoach Premium',
    title: 'FoodCoach Premium',
    benefits: [
      'Persoonlijk aangepast voedingsplan',
      'Sync Strava, Garmin, TrainingPeaks en meer',
      'Exclusieve FoodCoach AI ondersteuning',
      'Ontgrendel medailles, uitdagingen en focus plans',
      'Ontdek 1500+ Pro Recepten',
      'Introductie abonnement: {yearPrice}/jr of {monthPrice}/ma, gratis annuleren binnen 7 dagen na inschrijving',
      'Jaarplan: 14 dagen gratis proefperiode',
    ],
    postBenefitsLabel: '... en nog veel meer!',
    earlyBirdTag: 'Speciale korting',
    chooseYourPaymentTitle: 'Kies je betaalplan',
    discount: 'Bespaar {discount}%',
    $rc_annual: 'Jaarlijks',
    $rc_annual_description:
      'Eénmalige betaling voor een volledig jaar direct na de gratis proefperiode',
    $rc_monthly: 'Maandelijks',
    $rc_monthly_description: 'Maandelijkse betaling direct na de gratis proefperiode',
    subscribe: 'Abonneren',
    lookAround: 'Ik wil eerst rondkijken',
    useApp: 'Log in via de iOS of Android app om een abonnement te starten.',
    success: 'Je abonnement is succesvol afgesloten.',
    paymentFailed:
      'Je betaling is mislukt. Probeer het later opnieuw of neem contact op met support.',
    paymentCancelled: 'Je betaling is geannuleerd',
    statusUpdateFailed:
      'Je betaling is gelukt maar we konden je status niet updaten. Probeer het later opnieuw of neem contact op met support.',
    updatingSubscription: 'Je abonnementsgegevens worden bijgewerkt. \n Dit kan even duren.',
    notAvailable:
      'De abonnementen is momenteel niet beschikbaar. Om een goede ervaring te garanderen, verzoeken wij je te verifiëren dat je de meest recente versie van onze app gebruikt. Als het probleem zich blijft voordoen, probeer het dan later opnieuw of neem contact op met support voor verdere hulp.',
    updateToLatest: 'Update de app naar de laatste versie om je abonnement te starten.',
    restore: 'Herstel abonnement',
    restored: 'Je abonnement is hersteld.',
    restoreFailed:
      'Je abonnement kon niet worden hersteld. Probeer het later opnieuw of neem contact op met support.',
  },
};
