<script>
  import { JumButton, JumHeading } from '@blancofoodcoach/kompas';
  import { App as CapApp } from '@capacitor/app';
  import { Device } from '@capacitor/device';
  import { defineComponent } from 'vue';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';

  export default defineComponent({
    name: 'NiceModal',
    components: {
      DomPurify,
      JumButton,
      JumHeading,
    },
    props: {
      modal: {
        required: true,
        type: Object,
      },
      dismissible: {
        default: true,
        type: Boolean,
      },
    },
    emits: ['modal-closed'],
    data: () => ({
      listener: null,
    }),
    computed: {
      footerVisible() {
        return this.modal.onConfirm || this.modal.onCancel || this.$scopedSlots.footer;
      },
      bodyVisible() {
        return this.modal.message || this.$scopedSlots.body;
      },
    },
    async mounted() {
      const { platform } = await Device.getInfo();

      if (platform === 'android' && this.dismissible) {
        this.listener = CapApp.addListener('backButton', () => {
          this.modal.close();
        });
      }
    },
    beforeMount() {
      const { activeElement } = document;
      if (activeElement) activeElement.blur();
    },
    destroyed() {
      this.listener?.remove();
    },
  });
</script>

<template>
  <section ref="niceModal" class="nice-modal">
    <header class="nice-modal__header">
      <slot :modal="modal" name="title">
        <jum-heading h4 response class="nice-modal__title">
          <dom-purify :html="modal.title" />
        </jum-heading>
      </slot>
    </header>

    <div v-if="bodyVisible" class="nice-modal__body">
      <slot name="body" :modal="modal">
        <dom-purify class="nice-modal__message" tag="div" :html="modal.message" />
      </slot>
    </div>

    <div v-if="footerVisible" class="nice-modal__footer">
      <slot name="footer" :modal="modal">
        <jum-button
          v-if="modal.onCancel"
          class="nice-modal__footer-button"
          secondary
          medium
          @click="modal.cancel()"
        >
          {{ modal.cancelText }}
        </jum-button>
        <jum-button
          v-if="modal.onConfirm"
          class="nice-modal__footer-button"
          :secondary="!!modal.confirmSecondary"
          :primary="!modal.confirmSecondary"
          medium
          @click="modal.confirm()"
        >
          {{ modal.confirmText }}
        </jum-button>
      </slot>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .nice-modal {
    // Check if element has focus within
    --has-focus-within: true;

    background: $color-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 400px;
    overflow: hidden;
    padding-top: $spacing-m;
    position: fixed;
    text-align: left;
    width: calc(100% - #{$page-padding * 2});

    &__header {
      padding: $spacing-s $spacing-m 0;
    }

    &__body {
      background: $color-white;
      color: $color-black;
      font-size: $font-size-small;
      line-height: $line-height-small;
      margin: 0;
      padding: 0 $spacing-m;

      // stylelint-disable-next-line selector-pseudo-element-disallowed-list
      :deep(a) {
        color: $color-black;
      }
    }

    &__footer {
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      gap: 8px;
      justify-content: space-between;
      margin: auto;
      padding: $spacing-m;
      width: 100%;

      button {
        border-radius: 8px;
        display: block;
        text-align: center;
        width: 100%;
      }

      //button:nth-child(2) {
      //  background: $taf-red;
      //  color: $color-white;
      //}
    }

    &__breakout {
      margin-left: $spacing-m * -1;
      margin-right: $spacing-m * -1;
    }
  }
</style>
