import FcActionBar from '@/modules/core/components/action-bar/ActionBar';
import FcNavBar from '@/modules/core/components/nav-bar/NavBar';
import FcPageContentBreakout from '@/modules/core/components/page-content-breakout/PageContentBreakout';
import FcPageContent from '@/modules/core/components/page-content/PageContent';
import FcStickyBottom from '@/modules/core/components/sticky-bottom/StickyBottom';
import FcPage from '@/modules/core/components/page/Page';
import FcContentSpinner from '@/modules/shared/components/content-spinner/ContentSpinner';

const GlobalComponents = {
  install(Vue) {
    Vue.component('FcActionBar', FcActionBar);
    Vue.component('FcContentSpinner', FcContentSpinner);
    Vue.component('FcNavBar', FcNavBar);
    Vue.component('FcPage', FcPage);
    Vue.component('FcPageContent', FcPageContent);
    Vue.component('FcStickyBottom', FcStickyBottom);
    Vue.component('FcPageContentBreakout', FcPageContentBreakout);
  },
};

export default GlobalComponents;
