import axios from 'axios';
import { configService } from '@/config.service';

const http = axios.create({
  baseURL: configService.chatApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export default http;
