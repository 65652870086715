<script setup>
  import LinkCardListItem from '@/components/library/link-card-list-item/LinkCardListItem.vue';
  import { computed } from 'vue';

  const emit = defineEmits(['item-selected']);

  const props = defineProps({
    links: {
      type: Array,
      default: () => [],
    },
    tag: {
      type: String,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showFocus: {
      type: Boolean,
      default: false,
    },
  });

  const handleClick = link => {
    emit('item-selected', link);
  };

  const filteredLinks = computed(() => {
    if (props.isDisabled) {
      return props.links
        .filter(link => link !== null)
        .map(link => ({
          ...link,
          url: null,
        }));
    }

    return props.links.filter(link => link !== null);
  });
</script>

<template>
  <div class="link-card-list">
    <link-card-list-item
      v-for="(link, index) in filteredLinks"
      :key="`link-card-item-${index}`"
      :tag="tag"
      :link="link"
      :locked="link.locked"
      :show-focus="showFocus"
      @click.native="handleClick(link)"
    />
  </div>
</template>

<style scoped lang="scss">
  .link-card-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
