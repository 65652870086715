import { configService } from '@/config.service';
import { actionMapping } from '@/util/gamification/actions';

const { strapiImageUrl } = configService;

export class Doing {
  constructor(uuid, title, what, why, coverImageUrl, cardImageUrl, action, type, tag) {
    this.uuid = uuid;
    this.title = title;
    this.what = what;
    this.why = why;
    this.coverImageUrl = coverImageUrl;
    this.cardImageUrl = cardImageUrl;
    this.action = action;
    this.type = type;
    this.tag = tag;
  }

  get isCompleted() {
    return this.what.every(item => item.checked);
  }

  static createFromStrapi(resource, progress, stepUuid) {
    const { attributes } = resource;
    return new Doing(
      attributes.uuid,
      attributes.title,
      attributes?.what.map(item => ({
        uuid: `${stepUuid}.${item.uuid}`,
        text: item.text,
        checked: progress?.includes(`${stepUuid}.${item.uuid}`) ?? false,
      })) ?? [],
      attributes?.why,
      attributes.coverImage?.data?.attributes?.url
        ? `${strapiImageUrl}${attributes.coverImage.data.attributes.url}`
        : null,
      `${strapiImageUrl}${attributes.cardImage.data.attributes.url}`,
      actionMapping.get(attributes.type),
      attributes.type,
      attributes?.tag?.type
    );
  }
}
