class ConfigService {
  strapiApiUrl = this.getFromEnv('VUE_APP_STRAPI_API_URL', 'http://localhost:1337');

  strapiApiKey = this.getFromEnv('VUE_APP_STRAPI_API_KEY');

  strapiImageUrl = this.getFromEnv('VUE_APP_STRAPI_IMAGE_URL', 'http://localhost:1337');

  chatApiUrl = this.getFromEnv('VUE_APP_CHAT_API_URL', 'https://app.test.blancofoodcoach.com/chat');

  landingPageUrl = this.getFromEnv('VUE_LANDING_PAGE', 'https://www.test.blancofoodcoach.com');

  getFromEnv(key, fallback = null) {
    const value = process.env[key];

    if (value === undefined) {
      if (fallback !== undefined) {
        return fallback;
      }

      throw new Error(`Required ENV variable missing: ${key}`);
    }

    return value.trim();
  }
}

export const configService = new ConfigService();
